import { observable, decorate, computed, action, reaction, runInAction, toJS, autorun } from 'mobx'

import { IProduct, IVariant } from './product.store'
import { ICustomer, IVoucher } from './customer.model'
import { IModifier, modifierStore } from './modifier.store'
import _, {
  remove,
  flatten,
  some,
  find,
  forEach,
  head,
  reduce,
  cloneDeep,
  get,
  map,
  isEqual,
  sumBy,
  findLast,
  includes,
  keys
} from 'lodash'
import { ObjectId, getObjectId } from '../libs/object-id'

import { isBefore, addDays, startOfDay, isAfter, isSameDay } from 'date-fns'
import { RootStore } from './root-store'
import { IOrder } from './list-order.store'
import { handleAgentErrorResponse } from '../libs/error-handler'
import {
  getSelectedVariant,
  getItemSoldPrice,
  getPlatformPrice,
  getOriginalPrice,
  dayStringBetween
} from '../libs/order.util'
import { IOutlet } from './outlet-list.store'
import {
  getJarTetBuy6Get1Quantity,
  isBillTotalDiscountValid,
  isChinsuGiftValid,
  isComboBaeminT4,
  drinkDiscountMap,
  getDrinkDiscountQuantity,
  validGiftSharkTankDealFirework,
  getSubTOtalWithoutPricedGift,
  checkValidDiscountOpenS17,
  getStaffDiscountByBusiness,
  checkValidGiftFlag,
  getItemDiscount
} from '../libs/bulk-discount'
import { toast } from 'react-toastify'
import { partnerDiscounts, IPartnerDiscount } from './partner-discounts.store'
import { getCodeFromShiftRecordId } from './current-shift.store'
import {
  getTeacherDayGiftSkusMap,
  getXmasGiftSkusMap,
  teacherDaySkus,
  tetCookieSkus,
  xmasCakeSkus
} from '../libs/skus.utils'

export interface IItemModifier extends IModifier {
  quantity: number
}

export interface IDiscountItemMap {
  [key: string]: {
    id: string
    discount: number
    saleProgram: string
    noLoyalty?: boolean
  }
}

export type TPaymentType =
  | 'CASH'
  | 'TRANSFER'
  | 'MOMO'
  | 'ZALOPAY'
  | 'AIRPAY'
  | 'GRABPAY'
  | 'VNPAY'
  | 'VINID'
  | 'BAEMIN'
  | 'BE_FOOD'
  | 'GO_JEK'
  | 'LALAMOVE'
  | 'LOSHIP'
  | 'CARD'

export interface IPayment {
  _id?: string
  paymentType: TPaymentType
  code: string
  codes: string[]
  amount: number
  commissionAmount: number
  uniqueId: string
}

export type TOrderType = 'SHIP' | 'SHOP'

export type TCustomerCardStage = 'no customer' | 'not registered' | 'not activated' | 'activated'

export interface IOrderItem extends IProduct {
  id: string
  quantity: number
  modifiers: IItemModifier[]
  selectedVariant?: IVariant
  noLoyalty: boolean
  hasSaltedEgg?: boolean
  note?: string
  manufacturingDate?: Date
  programReminder?: string
  saleCode?: string
  saleCodeId?: string
  onlineOrderDiscount?: {
    discount: number
    saleProgram?: string
  }
}

export interface IComputedItem extends IOrderItem {
  soldPrice: number
  lineTotal: number
  discount?: number
  originalPrice?: number
  saleProgram?: string
}

export interface IDiscount {
  description: string
  amount: number
}

export type TShippingProvider =
  | 'Ship ngoài'
  | 'Ship nội bộ'
  | 'Lalamove'
  | 'Giao tại cửa hàng'
  | 'Ahamove'
export type TOrderPlatform =
  | 'SAVOR_SHOP'
  | 'NOW'
  | 'LOSHIP'
  | 'GRAB_FOOD'
  | 'GO_FOOD'
  | 'VINID'
  | 'BAEMIN'
  | 'BE_FOOD'
  | 'LALAMOVE'

export type TItemOption = {
  sku: string
  description: string
  countAs: number
  price?: number
}
export type TComboElement = {
  name: string
  selectedItem?: { sku: string }
  itemOptions: TItemOption[]
  maxQuantity: number
}

export class OrderStore {
  [x: string]: any
  rootStore!: RootStore
  loadRootStore(rootStore: RootStore) {
    runInAction(() => {
      this.rootStore = rootStore
    })
  }
  _id: string = ObjectId()
  orderId: string = ''
  momoTransId: string = ''
  couponCode?: string = ''
  covidCoupon?: string = 'Combo'
  voucherCode?: string = 'NONE'
  couponValidated?: boolean = false
  outlet: IOutlet | string = ''
  items: IOrderItem[] = []
  product?: IProduct | null
  customer: ICustomer | null = null
  computedCustomer: ICustomer | null = null
  voucher: IVoucher | null = null
  selectedOrderPlatform: TOrderPlatform = 'SAVOR_SHOP'
  payments?: IPayment[] = []
  shippingProvider?: TShippingProvider | null
  shippingPay: number = 0
  shippingCollect: number = 0
  note: string = ''
  shippingBillNumber?: string = ''
  shippingPaymentMethod?: string = ''
  shippingCustomerName?: string = ''
  shippingCustomerPhone?: string = ''
  shippingCustomerAddress?: string = ''
  onlineOrderId?: string = ''
  selectedServiceMode: string = ''
  status?: 'ordered' | 'done' | null
  ordered?: Date | string
  updated?: Date | string
  autosave: boolean = false
  autoUpdateProductStatus: boolean = true
  openModalPayment: boolean = false
  saving?: boolean = false
  receivedMoney?: number | null = null
  orderPrinting?: any = null
  saleEntryPrinting?: any = null
  activeNowOrders?: any = []
  onlineOrder?: any = null
  deposit: number = 0
  nowOrderCode: string = ''
  linkQrcode: string = ''
  linkQrTransfer: string = ''
  linkQrTransferError: string = ''
  orderCode?: String | null = null
  goFoodPin?: String | null = null
  transferCode?: String | null = null
  groupPaid?: boolean
  codes?: string[] = []

  getOutletCode() {
    const currentShiftStore = this.rootStore.currentShiftStore
    const currentOutlet = currentShiftStore.currentOutlet
    const currentShift = currentShiftStore.currentShift
    const currentShifftOutlet = currentShift ? currentShift.outlet : null

    let outlet = currentOutlet || currentShifftOutlet

    let outletCode = _.get(outlet, 'code')
    if (!outletCode && currentShift) {
      outletCode = getCodeFromShiftRecordId(currentShift.shiftRecordId) || ''
    }

    return outletCode
  }

  constructor() {
    // Add autorun to track changes in observables
    autorun(() => {
      // console.log('Items:', toJS(this.items))
      // console.log('Customer:', toJS(this.customer))
      // console.log('Voucher:', toJS(this.voucher))
      // console.log('Available Vouchers:', toJS(this.availableVouchers))
      // console.log('Discounts:', this.discounts)
    })
  }

  setCouponCode(couponCode: string) {
    this.couponCode = couponCode
  }

  setCovidCoupon(covidCoupon: string) {
    this.covidCoupon = covidCoupon
  }
  setVoucherCode(voucherCode: string) {
    this.voucherCode = voucherCode
  }

  setCouponCodeValidated() {
    this.couponValidated = true
  }

  get computedReceivableAmount() {
    if (this.payments) {
      return this.payments.reduce((total, payment) => {
        if (!['CASH', 'TRANSFER'].includes(payment.paymentType)) {
          return total + payment.amount
        }
        return total
      }, 0)
    }
    return 0
  }

  get computedServiceMode() {
    if (this.orderPlatform !== 'SAVOR_SHOP' || this.shippingProvider) {
      return 'delivery'
    }
    return this.selectedServiceMode
  }

  get computedReceiveAmount() {
    return this.cashTotal
  }

  get commissionAmount() {
    if (this.orderPlatform === 'LOSHIP') {
      if (!this.couponCode) {
        return this.saleTotal * (20 / 100)
      } else {
        return 0
      }
    }
    if (this.orderPlatform === 'NOW') {
      return this.saleTotal * (15 / 100)
    }
    if (this.orderPlatform === 'LALAMOVE') {
      const totalWithoutDiscount = reduce(
        this.items,
        (result, item) => {
          if (getItemDiscount(item, this.discountItemMap) > 0) {
            return result
          }
          return result + item.normalPrice * item.quantity
        },
        0
      )
      return totalWithoutDiscount * (15 / 100)
    }

    return 0
  }

  get commissionableAmount() {
    if (this.orderPlatform === 'GRAB_FOOD') {
      return (this.saleTotal * 15) / 100
    }
    if (this.orderPlatform === 'GO_FOOD') {
      return this.saleTotal * (15 / 100)
    }
    if (this.orderPlatform === 'BE_FOOD') {
      return this.saleTotal * (15 / 100)
    }
    if (this.orderPlatform === 'BAEMIN') {
      return this.saleTotal * (17 / 100)
    }
    return 0
  }

  get commissionPercentage() {
    return (this.commissionAmount + this.commissionableAmount) / this.saleTotal
  }

  get orderType(): TOrderType {
    if (this.shippingProvider) {
      return 'SHIP'
    }
    return 'SHOP'
  }

  getPercentDiscount() {
    return 20
  }

  getFlavourMilkTeaSkus() {
    return ['V0710', 'V1804', 'V1805', 'V0708', 'V1807', 'V1809']
  }
  // getBuy1Get1Program(item: IOrderItem) {
  //   const soldPrice = getItemSoldPrice(item, this.orderPlatform, this.orderType)
  //   const flavorMilkTeaSkus = this.getFlavourMilkTeaSkus()

  //   if (item.sku === 'V2331') {
  //     return {
  //       reminder: 'Được tặng topping nha đam cùng số lượng',
  //       saleProgram: 'Mua 1 trà ô long chanh leo tặng 1 topping nha đam',
  //       modifier: 'V0759'
  //     }
  //   }
  //   if (flavorMilkTeaSkus.includes(item.sku)) {
  //     return {
  //       reminder: 'Được tặng topping trân châu trắng cùng số lượng',
  //       saleProgram: 'Mua 1 trà sữa vị tặng 1 topping trân châu trắng',
  //       modifier: 'V0657'
  //     }
  //   }
  //   if (item.category === 'sticky-rice' && soldPrice >= 35000) {
  //     return {
  //       reminder: 'Được tặng topping chả cùng số lượng',
  //       saleProgram: 'Mua 1 xôi bất kì trên 35k tặng 1 topping chả',
  //       modifier: 'V0358'
  //     }
  //   }
  //   return null
  // }

  getDiscountPercentForCakeOrder(total: number, isStaff?: boolean) {
    if (isStaff && total < 20000000) {
      return { discountWithBox: 0.3, discountWithoutBox: 0.35 }
    }
    if (total >= 20000000) {
      return { discountWithBox: 0.4, discountWithoutBox: 0.45 }
    }
    if (total >= 6000000) {
      return { discountWithBox: 0.3, discountWithoutBox: 0.35 }
    }
    if (total >= 2000000) {
      return { discountWithBox: 0.25, discountWithoutBox: 0.3 }
    }
    if (total >= 1000000) {
      return { discountWithBox: 0.2, discountWithoutBox: 0.25 }
    }
    return { discountWithBox: 0, discountWithoutBox: 0.2 }
  }

  checkValidGiftDeadline8t3(date: Date) {
    return (
      isAfter(date, new Date('2024-03-07T00:00:00+07:00')) &&
      isBefore(date, new Date('2024-03-09T00:00:00+07:00'))
    )
  }

  get discountItemMap(): IDiscountItemMap {
    const resultMap: IDiscountItemMap = this.items.reduce((result: IDiscountItemMap, item) => {
      result[item.id] = {
        id: item.id,
        discount: 0,
        saleProgram: ''
      }
      return result
    }, {})

    const deliverDeadline =
      this.onlineOrder && this.onlineOrder.deadline
        ? new Date(this.onlineOrder.deadline)
        : new Date()

    const MINI_MOONCAKE_SKU = 'V9016'
    const mooncakeSetsCountAsMap: { [key: string]: number } = {
      V9010: 4,
      V9011: 4, // old bạch liên
      V9153: 4, // new kim long
      V9154: 4, // new kim long hoa thu
      V9012: 2,
      V9013: 2,
      V9014: 4
    }
    mooncakeSetsCountAsMap[MINI_MOONCAKE_SKU] = 0 // có mốc sỉ riêng
    const mooncakeSetSkus = keys(mooncakeSetsCountAsMap)
    const mooncake2024Skus = [
      'V1691',
      'V1686',
      'V1688',
      'V9929',
      'V1792',
      'V1689',
      'V9938',
      'V9945',
      'V9017',
      'V9018',
      'V9019',
      'V9020',
      'V9021',
      'V9022',
      ...mooncakeSetSkus
    ]
    const vietnameseWomenDaySkus = [
      'V9163',
      'V9165',
      'V9167',
      'V9169',
      'V9171',
      'V9173',
      'V9175',
      'V9177',
      'V9179',
      'V9181',
      'V9183',
      'V9185',
      'V9187',
      'V9189',
      'V9191',
      'V9193',
      'V9195',
      'V9197',
      'V9199',
      'V9201',
      // mã cũ
      'V8993',
      'V8321',
      'V8327',
      // mo them
      'V9041',
      'V9045',
      'V8838',
      // them 2
      'V2879',
      'V2881',
      'V2883',
      'V2662',
      'V2663',
      'V2664',
      'V2709',
      'V2711',
      'V2713',
      'V1393',
      'V1394',
      'V1460',
      'V1849',
      'V1864',
      'V1879',
      'V1506',
      'V1507',
      'V1531',
      'V2349',
      'V2359',
      'V2369',
      'V2352',
      'V2362',
      'V2372'
    ]
    const mousseSetSkus = ['V8321', 'V8327']
    const newCakeSkus = ['V8461', 'V8463', 'V8465', 'V8467', 'V8469']
    const globalWomenDaySkus = [...mousseSetSkus, ...newCakeSkus, 'V1596', 'V2364', 'V2374']

    // if (
    //   some(this.items, { category: 'birthday-cake' }) &&
    //   isBillTotalDiscountValid(this.items, this.orderPlatform, this.orderType) &&
    //   isBefore(new Date(), new Date('2023-10-11T00:00:00+07:00'))
    // ) {
    //   let totalDiscount = 1
    //   this.items.forEach(item => {
    //     if (!mooncakeSkus.includes(item.sku) || totalDiscount < 1) {
    //       return
    //     }

    //     let soldPrice = getItemSoldPrice({
    //       item,
    //       orderPlatform: this.orderPlatform,
    //       orderType: this.orderType
    //     })

    //     const numDiscount = Math.min(totalDiscount, item.quantity)

    //     resultMap[item.id].discount = soldPrice * numDiscount
    //     resultMap[item.id].saleProgram = 'Tặng bánh trung thu'
    //     resultMap[item.id].noLoyalty = true

    //     totalDiscount -= numDiscount
    //   })
    // }
    // 20/10 vietnamese women day
    if (
      some(this.items, item => vietnameseWomenDaySkus.includes(item.sku)) &&
      isAfter(new Date(), new Date('2024-10-18T00:00:00+07:00')) &&
      isBefore(new Date(), new Date('2024-10-21T00:00:00+07:00'))
    ) {
      const outletCode = this.getOutletCode()
      if (['E9', 'B7', 'E62'].includes(outletCode)) {
        this.items.forEach(item => {
          if (item.description.match(/vừa/i)) {
            resultMap[item.id].discount = 100000 * item.quantity
            resultMap[item.id].saleProgram = 'Giảm giá bánh vừa 20/10'
            resultMap[item.id].noLoyalty = true
          }
        })
      }
      // Tặng thiệp
      let discountItemNum = _.sumBy(this.items, item => {
        if (vietnameseWomenDaySkus.includes(item.sku)) {
          return item.quantity
        }
        return 0
      })
      this.items.forEach(item => {
        if (item.sku !== 'V1816' || discountItemNum <= 0) return
        let soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })
        const discountQuantity = Math.min(discountItemNum, item.quantity)
        resultMap[item.id].discount = soldPrice * discountQuantity
        resultMap[item.id].saleProgram = 'Tặng thiệp 20/10'
        resultMap[item.id].noLoyalty = true
        discountItemNum -= discountQuantity
      })
      // Tặng hoa
      if (
        ['B4', 'B5', 'B7', 'E62'].includes(outletCode) &&
        isAfter(new Date(), new Date('2024-10-20T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2024-10-21T00:00:00+07:00'))
      ) {
        let discountItemNum = 1
        this.items.forEach(item => {
          if (item.sku !== 'V8474' || discountItemNum <= 0) return
          let soldPrice = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType
          })
          const discountQuantity = Math.min(discountItemNum, item.quantity)
          resultMap[item.id].discount = soldPrice * discountQuantity
          resultMap[item.id].saleProgram = 'Tặng hoa hồng một bông màu đỏ hộp mica đơn 20/10'
          resultMap[item.id].noLoyalty = true
          discountItemNum -= discountQuantity
        })
      }
    }

    // 8/3 international women day
    if (
      this.checkValidGiftDeadline8t3(new Date()) &&
      some(this.items, item => globalWomenDaySkus.includes(item.sku))
    ) {
      let discountItemNum = _.sumBy(this.items, item => {
        if (globalWomenDaySkus.includes(item.sku)) {
          return item.quantity
        }
        return 0
      })
      this.items.forEach(item => {
        if (item.sku !== 'V2245' || discountItemNum <= 0) return
        let soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })
        const discountQuantity = Math.min(discountItemNum, item.quantity)
        resultMap[item.id].discount = soldPrice * discountQuantity
        resultMap[item.id].saleProgram = 'Tặng thiệp 8/3'
        resultMap[item.id].noLoyalty = true
        discountItemNum -= discountQuantity
      })
    }
    // Chương trình mua bánh tặng thiệp và hộp mica
    if (isBefore(new Date(), new Date('2023-05-16T00:00:00+07:00'))) {
      const miniMotherSkus = ['V2352', 'V2414']
      const normalMotherSkus = ['V2362', 'V2415']
      const bigMotherSkus = ['V2372', 'V2416']

      const now = new Date()
      const giftItems = []
      if (isBefore(now, new Date('2023-05-16T00:00:00+07:00'))) {
        const miniNumItem = _.sumBy(this.items, item => {
          if (miniMotherSkus.includes(item.sku)) {
            return item.quantity
          }
          return 0
        })
        const normalNumItem = _.sumBy(this.items, item => {
          if (normalMotherSkus.includes(item.sku)) {
            return item.quantity
          }
          return 0
        })
        const bigNumItem = _.sumBy(this.items, item => {
          if (bigMotherSkus.includes(item.sku)) {
            return item.quantity
          }
          return 0
        })
        if (miniNumItem) {
          giftItems.push({ sku: 'V1953', quantity: miniNumItem })
        }
        if (normalNumItem) {
          giftItems.push({ sku: 'V1954', quantity: normalNumItem })
        }
        if (bigNumItem) {
          giftItems.push({ sku: 'V1955', quantity: bigNumItem })
        }
      }
      // if (isAfter(now, new Date('2023-03-07T00:00:00+07:00'))) {
      //   const numItem = _.sumBy(this.items, item => {
      //     if (skus.includes(item.sku)) {
      //       return item.quantity
      //     }
      //     return 0
      //   })

      //   if (numItem) {
      //     giftItems.push({ sku: 'V2245', quantity: 1 })
      //   }
      // }
      if (giftItems.length) {
        _.forEach(giftItems, giftItem => {
          let numDiscountItem = giftItem.quantity

          this.items.forEach(item => {
            if (item.sku === giftItem.sku && numDiscountItem > 0) {
              const soldPrice = getItemSoldPrice({
                item,
                orderPlatform: this.orderPlatform,
                orderType: this.orderType
              })
              const discount = soldPrice * Math.min(numDiscountItem, item.quantity)
              numDiscountItem -= item.quantity
              resultMap[item.id].discount = discount
              resultMap[item.id].saleProgram = 'Tặng quà ngày của mẹ'
              resultMap[item.id].noLoyalty = true
            }
          })
        })
      }
    }
    // 20/11
    if (
      isAfter(deliverDeadline, new Date('2024-11-15T00:00:00+07:00')) &&
      isBefore(deliverDeadline, new Date('2024-11-21T00:00:00+07:00'))
    ) {
      const pickyGiftSkus = getTeacherDayGiftSkusMap().picky
      const autoGiftSku = head(getTeacherDayGiftSkusMap().auto)

      const totalValue = this.items.reduce((result, item) => {
        if (getTeacherDayGiftSkusMap().all.includes(item.sku)) {
          return result
        }
        return (
          item.quantity *
            getItemSoldPrice({
              item,
              orderPlatform: this.orderPlatform,
              orderType: this.orderType
            }) +
          result
        )
      }, 0)

      const validPickyGift =
        totalValue >= 300000 &&
        (!this.onlineOrder ||
          get(this.onlineOrder, 'shipping.provider', '') === 'Giao tại cửa hàng')

      let byValueDiscountAvailNum = validPickyGift ? 1 : 0
      let byQtyDiscountAvailNum = _.sumBy(this.items, item => {
        if (teacherDaySkus.includes(item.sku)) {
          return item.quantity
        }
        return 0
      })

      this.items.forEach(item => {
        // không có mã tặng
        if (![...pickyGiftSkus, autoGiftSku].includes(item.sku)) {
          return
        }

        let soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })

        if (pickyGiftSkus.includes(item.sku)) {
          if (!byValueDiscountAvailNum) {
            return
          }
          const discountQuantity = Math.min(byValueDiscountAvailNum, item.quantity)
          resultMap[item.id].discount = soldPrice * discountQuantity
          resultMap[item.id].saleProgram = 'Tặng que cắm giấy Teacher Day'
          resultMap[item.id].noLoyalty = true
          byValueDiscountAvailNum -= discountQuantity
        }

        // Xét mã tặng theo số lượng
        if (item.sku !== autoGiftSku) {
          return
        }
        // đơn ko có bánh 20/11 hoặc giảm max rồi
        if (!byQtyDiscountAvailNum) {
          return
        }
        const discountQuantity = Math.min(byQtyDiscountAvailNum, item.quantity)
        resultMap[item.id].discount = soldPrice * discountQuantity
        resultMap[item.id].saleProgram = 'Tặng que cắm mica Teacher Day'
        resultMap[item.id].noLoyalty = true
        byQtyDiscountAvailNum -= discountQuantity
      })
    }

    // xmas
    if (
      isAfter(deliverDeadline, new Date('2024-12-18T00:00:00+07:00')) &&
      isBefore(deliverDeadline, new Date('2024-12-26T00:00:00+07:00'))
    ) {
      const valueGiftSkus = getXmasGiftSkusMap().value
      const quantityGiftSku = head(getXmasGiftSkusMap().quantity)

      const totalValue = this.items.reduce((result, item) => {
        if ([...valueGiftSkus, quantityGiftSku].includes(item.sku)) {
          return result
        }
        return (
          item.quantity *
            getItemSoldPrice({
              item,
              orderPlatform: this.orderPlatform,
              orderType: this.orderType
            }) +
          result
        )
      }, 0)
      const outletCode = this.getOutletCode()

      const validValueGift =
        totalValue >= 300000 &&
        (!this.onlineOrder ||
          get(this.onlineOrder, 'shipping.provider', '') === 'Giao tại cửa hàng') &&
        outletCode !== 'E9'

      let byValueDiscountAvailNum = validValueGift ? 1 : 0
      let byQtyDiscountAvailNum = _.sumBy(this.items, item => {
        if (xmasCakeSkus.includes(item.sku)) {
          return item.quantity
        }
        return 0
      })

      this.items.forEach(item => {
        // không có mã tặng
        if (![...valueGiftSkus, quantityGiftSku].includes(item.sku)) {
          return
        }

        let soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })

        if (valueGiftSkus.includes(item.sku)) {
          if (!byValueDiscountAvailNum) {
            return
          }
          const discountQuantity = Math.min(byValueDiscountAvailNum, item.quantity)
          resultMap[item.id].discount = soldPrice * discountQuantity
          resultMap[item.id].saleProgram = 'Tặng thiệp Giáng sinh'
          resultMap[item.id].noLoyalty = true
          byValueDiscountAvailNum -= discountQuantity
        }

        // Xét mã tặng theo số lượng
        // - mã đang loop qua ko phải mã tặng
        if (item.sku !== quantityGiftSku) {
          return
        }
        // - đơn ko có bánh xmas hoặc giảm max rồi
        if (!byQtyDiscountAvailNum) {
          return
        }
        const discountQuantity = Math.min(byQtyDiscountAvailNum, item.quantity)
        resultMap[item.id].discount = soldPrice * discountQuantity
        resultMap[item.id].saleProgram = 'Tặng que cắm Giáng sinh'
        resultMap[item.id].noLoyalty = true
        byQtyDiscountAvailNum -= discountQuantity
      })
    }

    // Bánh kẹo tết đầu mùa combo 2 lọ cookie
    if (_.some(this.items, item => tetCookieSkus.includes(item.sku))) {
      // Sort các mã cookie để ưu tiên giảm giá phần combo cho mã có giá bán cao hơn => lợi hơn cho khách
      const sortedItems = _.orderBy(
        this.items,
        [
          // First criteria: is it a Tet cookie (true comes first)
          item => tetCookieSkus.includes(item.sku),
          // Second criteria: price (only matters for Tet cookies)
          item =>
            tetCookieSkus.includes(item.sku)
              ? getItemSoldPrice({
                  item,
                  orderPlatform: this.orderPlatform,
                  orderType: this.orderType
                })
              : 0
        ],
        ['desc', 'desc']
      )

      // Duyệt từng mã cookie để tính giảm giá
      const COMBO_COOKIE_SALE_PRICE_EACH = 99000 / 2
      const cookieCount = _.sumBy(this.items, item => {
        if (tetCookieSkus.includes(item.sku)) {
          return item.quantity
        }
        return 0
      })
      let totalDiscountQuantity = Math.floor(cookieCount / 2) * 2
      sortedItems.forEach(item => {
        if (!tetCookieSkus.includes(item.sku)) {
          return
        }
        if (totalDiscountQuantity <= 0) {
          return
        }
        const discountQuantity = Math.min(item.quantity, totalDiscountQuantity)
        const soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })
        resultMap[item.id].discount = (soldPrice - COMBO_COOKIE_SALE_PRICE_EACH) * discountQuantity
        resultMap[item.id].saleProgram = 'Combo 2 lọ cookies'
        totalDiscountQuantity -= discountQuantity
        return item
      })
    }

    const nougatSkus = ['V9323', 'V9335']
    if (
      isAfter(deliverDeadline, new Date('2024-12-13T00:00:00+07:00')) &&
      isBefore(deliverDeadline, new Date('2024-12-26T00:00:00+07:00')) &&
      _.some(this.items, item => nougatSkus.includes(item.sku))
    ) {
      this.items.forEach(item => {
        if (!nougatSkus.includes(item.sku)) {
          return
        }
        const soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })
        resultMap[item.id].discount = ((soldPrice * 20) / 100) * item.quantity
        resultMap[item.id].saleProgram = 'Giảm giá trải nghiệm nougat đầu mùa'
        resultMap[item.id].noLoyalty = true
      })
    }

    // Chương trình giảm giá mua combo bánh/xôi + nước
    const drinkValidQuantity = getDrinkDiscountQuantity(this.items)
    if (drinkValidQuantity) {
      let totalDiscount = drinkValidQuantity
      this.items.forEach(item => {
        if (!item.canDiscount || item.comboId) {
          return
        }
        const selectedVariant = getSelectedVariant(item)
        const matchSku = drinkDiscountMap[selectedVariant ? selectedVariant.sku : item.sku]
        if (!matchSku) {
          return
        }
        const numDiscount = Math.min(totalDiscount, item.quantity)
        if (!numDiscount) {
          return
        }
        const soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType,
          withoutModifier: true
        })
        resultMap[item.id].discount = (soldPrice - matchSku.salePrice) * numDiscount
        resultMap[item.id].saleProgram = 'Giảm giá nước mua kèm bánh mì/xôi'
        totalDiscount -= numDiscount
      })
    }

    this.items.forEach(item => {
      const expiryDays = item.shelfLife ? item.shelfLife / 2 : 0
      if (item.saleCode) {
        return
      }
      if (item.sku === 'V1408') {
        const soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })
        const discount = soldPrice - Math.floor((soldPrice * 75) / 100 / 10000) * 10000

        resultMap[item.id].discount = discount
        resultMap[item.id].saleProgram = 'Giảm giá combo bánh ngọt'
        resultMap[item.id].noLoyalty = true
      }
    })

    // Tặng kẹo singum
    let numDiscountSinggum = 1
    this.items.forEach(item => {
      if (item.sku !== 'V8777' || !numDiscountSinggum) {
        return
      }
      const soldPrice = getItemSoldPrice({
        item,
        orderPlatform: this.orderPlatform,
        orderType: this.orderType
      })
      const discountQuantity = Math.min(item.quantity, 1)
      resultMap[item.id].discount = soldPrice * discountQuantity
      resultMap[item.id].saleProgram = 'Chương trình tặng Kẹo sing-gum doublemint sample'
      numDiscountSinggum -= discountQuantity
    })
    // Tặng tương ớt Chinsu
    if (isChinsuGiftValid(this.items)) {
      let numDiscount = 3
      this.items.forEach(item => {
        if (item.sku !== 'V2857' || !numDiscount) {
          return
        }
        const soldPrice = getItemSoldPrice({
          item,
          orderPlatform: this.orderPlatform,
          orderType: this.orderType
        })
        const discountQuantity = Math.min(item.quantity, numDiscount)
        resultMap[item.id].discount = soldPrice * discountQuantity
        resultMap[item.id].saleProgram = 'Chương trình Grab tặng Tương ớt Chinsu'
        numDiscount -= discountQuantity
      })
    }

    if (this.orderPlatform === 'SAVOR_SHOP') {
      // Tặng cờ quốc khánh
      if (checkValidGiftFlag(this.onlineOrder, this.orderPlatform, this.items)) {
        let numDiscountFlag = 1
        this.items.forEach(item => {
          if (item.sku !== 'V9142' || !numDiscountFlag) {
            return
          }
          const soldPrice = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType
          })
          const discountQuantity = Math.min(item.quantity, numDiscountFlag)
          resultMap[item.id].discount = soldPrice * discountQuantity
          resultMap[item.id].saleProgram = 'Tặng cờ mừng Quốc Khánh'
          numDiscountFlag -= discountQuantity
        })
      }
      // Tặng bóng nổ pháo giấy deal Shark tank
      const outlet = this.rootStore.currentShiftStore.currentShift
        ? this.rootStore.currentShiftStore.currentShift.outlet
        : null
      if (
        this.computedCustomer &&
        outlet &&
        validGiftSharkTankDealFirework(this.items, outlet, this.computedCustomer)
      ) {
        let numDiscountItem = 3
        _.forEach(this.items, item => {
          if (!numDiscountItem) {
            return
          }
          if (item.sku !== 'V2615') {
            return
          }
          const soldPrice = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType
          })
          const discountQuantity = Math.min(item.quantity, numDiscountItem)
          resultMap[item.id].discount = soldPrice * discountQuantity
          resultMap[item.id].saleProgram = 'Deal Shark Tank Tặng bóng nổ pháo giâý'
          numDiscountItem -= discountQuantity
        })
      }
      if (
        !get(this.computedCustomer, 'isStaff') &&
        this.rootStore.currentShiftStore.currentShift &&
        this.rootStore.currentShiftStore.currentShift.shiftRecordId.match(/(S19)/) &&
        isBefore(new Date(), new Date('2022-11-27T23:59:59+07:00'))
      ) {
        let numDiscountItem = _.sumBy(this.items, item => {
          if (!['bread', 'milk-tea'].includes(item.category)) {
            return 0
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          if (basePrice >= 30000) {
            return item.quantity
          }
          return 0
        })
        _.forEach(this.items, item => {
          if (!numDiscountItem) {
            return
          }
          if (!['savor-drink', 'juice', 'milk-tea'].includes(item.category)) {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          if (basePrice > 25000) {
            return
          }
          const discountQuantity = Math.min(item.quantity, numDiscountItem)
          resultMap[item.id].discount = basePrice * discountQuantity
          resultMap[item.id].saleProgram =
            'Giảm giá nước khi mua bánh/trà sữa lớn hơn hoặc bằng 30k'
          resultMap[item.id].noLoyalty = true
          numDiscountItem -= discountQuantity
        })
      }

      if (
        this.customer &&
        get(this.computedCustomer, 'numRemainDiscount', 0) > 0 &&
        get(this.computedCustomer, 'drinkSkus', []).length > 0
      ) {
        let numDiscountItem = get(this.computedCustomer, 'numRemainDiscount', 0)
        let remainDiscountValue = get(this.computedCustomer, 'remainDiscountValue')
        const drinkSkus: string[] = get(this.computedCustomer, 'drinkSkus', [])

        this.items.forEach(item => {
          if (!numDiscountItem || !drinkSkus.length) {
            return
          }
          if (drinkSkus.includes(item.sku) && !item.comboName) {
            const basePrice = getPlatformPrice(item, this.orderPlatform)
            const discountQty = remainDiscountValue ? item.quantity : 1
            const rootDiscount = basePrice * 1 * discountQty
            resultMap[item.id].discount = remainDiscountValue
              ? Math.min(rootDiscount, remainDiscountValue)
              : rootDiscount
            resultMap[item.id].saleProgram = 'Giảm giá theo chương trình tặng nước cho nhân viên'
            resultMap[item.id].noLoyalty = true
            numDiscountItem -= 1
          }
        })
      }
      if (this.customer && get(this.computedCustomer, 'tetCakeCandySkus', []).length > 0) {
        const tetCakeCandySkus: string[] = get(this.computedCustomer, 'tetCakeCandySkus', [])
        const tetCakeCandyProducts = get(this.computedCustomer, 'tetCakeCandyProducts', []) as {
          sku: string
          salePrice?: number
        }[]
        const saleMap: { [key: string]: number } = _.reduce(
          tetCakeCandyProducts,
          (result, item) => {
            if (item.salePrice) {
              result[item.sku] = item.salePrice
            }
            return result
          },
          {} as { [key: string]: number } // Add index signature
        )
        let percent = get(this.computedCustomer, 'tetCakeCandyPercent', 0)
        this.items.forEach(item => {
          if (!tetCakeCandySkus.length) {
            return
          }
          if (tetCakeCandySkus.includes(item.sku) && !item.comboName) {
            const basePrice = getPlatformPrice(item, this.orderPlatform)
            let discountValue = basePrice * (percent / 100)
            if (saleMap[item.sku]) {
              discountValue = basePrice - saleMap[item.sku]
            }
            resultMap[item.id].discount = discountValue * item.quantity
            resultMap[item.id].saleProgram = 'Giảm giá bánh kẹo tết cho nhân viên'
            resultMap[item.id].noLoyalty = true
          }
        })
      }
      if (
        some(this.items, item => mooncake2024Skus.includes(item.sku)) &&
        isBefore(
          this.onlineOrder && this.onlineOrder.deadline
            ? new Date(this.onlineOrder.deadline)
            : new Date(),
          new Date('2024-09-18T00:00:00+07:00')
        )
      ) {
        // ưu tiên giảm theo NV trước, nếu hết lượt giảm thì xét giảm như khách thường
        if (
          this.customer &&
          get(this.computedCustomer, 'numRemainMooncakeDiscount', 0) > 0 &&
          get(this.computedCustomer, 'mooncakeSkus', []).length > 0
        ) {
          const mooncakeSkus: string[] = get(this.computedCustomer, 'mooncakeSkus', [])
          const percent = get(this.computedCustomer, 'mooncakePercent', 0)
          let availableDiscount = 500000
          this.items.forEach(item => {
            if (!mooncakeSkus.length || !availableDiscount) {
              return
            }
            if (mooncakeSkus.includes(item.sku) && !item.comboName) {
              const basePrice = getPlatformPrice(item, this.orderPlatform)
              resultMap[item.id].discount =
                Math.min(availableDiscount, basePrice * (percent / 100)) * item.quantity
              resultMap[item.id].saleProgram = 'Giảm giá bánh trung thu cho nhân viên'
              resultMap[item.id].noLoyalty = true
              availableDiscount -= resultMap[item.id].discount
            }
          })
        } else {
          const bundledSales = [
            {
              qty: 20,
              discount: 10
            },
            {
              qty: 40,
              discount: 20
            },
            {
              qty: 100,
              discount: 30
            },
            {
              qty: 400,
              discount: 35
            },
            {
              qty: 1000,
              discount: 40
            },
            {
              qty: 3000,
              discount: 45
            }
          ]
          const miniBundledSales = [
            // tính theo set, ko theo số bánh
            {
              qty: 10,
              discount: 10
            },
            {
              qty: 40,
              discount: 20
            },
            {
              qty: 100,
              discount: 30
            },
            {
              qty: 400,
              discount: 35
            },
            {
              qty: 1000,
              discount: 40
            }
          ]
          const countMooncakeMap: { [key: string]: number } = reduce(
            this.items,
            (result, item) => {
              if (!mooncake2024Skus.includes(item.sku)) {
                return result
              }
              if (item.sku === MINI_MOONCAKE_SKU) {
                return { ...result, mini: result.mini + item.quantity }
              }
              return {
                ...result,
                normal: result.normal + item.quantity * (mooncakeSetsCountAsMap[item.sku] || 1)
              }
            },
            {
              mini: 0,
              normal: 0
            }
          )
          this.items.forEach(item => {
            if (!mooncake2024Skus.includes(item.sku)) {
              return
            }
            const soldPrice = getItemSoldPrice({
              item,
              orderPlatform: this.orderPlatform,
              orderType: this.orderType
            })
            const totalQtyForDiscount =
              countMooncakeMap[item.sku === MINI_MOONCAKE_SKU ? 'mini' : 'normal']
            // giữa 20% discount trial đầu mùa và % sỉ nếu có, lấy cái giảm nhiều hơn cho khách
            let discountRate = 0
            const adhocDiscountRate = 20
            const bundleDiscountRate = _.get(
              _.head(
                _.filter(
                  item.sku === MINI_MOONCAKE_SKU ? miniBundledSales : bundledSales,
                  sale => totalQtyForDiscount >= sale.qty
                )
              ),
              'discount',
              0
            )
            const adhocDiscount =
              isBefore(deliverDeadline, new Date('2024-09-02T00:00:00+07:00')) &&
              isAfter(deliverDeadline, new Date('2024-08-27T00:00:00+07:00')) &&
              bundleDiscountRate < adhocDiscountRate
            if (adhocDiscount) {
              if (item.sku === 'V9014') {
                return
              }
              discountRate = adhocDiscountRate
            } else if (bundleDiscountRate) {
              discountRate = bundleDiscountRate
            }
            if (!discountRate) {
              return
            }
            resultMap[item.id].discount = (soldPrice * item.quantity * discountRate) / 100
            resultMap[item.id].saleProgram = adhocDiscount
              ? 'Flashsale bánh trung thu mừng Quốc Khánh'
              : 'Sỉ bánh trung thu theo số lượng tổng đơn'
            resultMap[item.id].noLoyalty = true
          })
        }
      }

      const mooncakeEndSaleSkus = [
        'V1792',
        'V1688',
        'V1686',
        'V1691',
        'V9018',
        'V9929',
        'V9019',
        'V9938',
        'V9945'
      ]
      if (
        some(this.items, item => mooncakeEndSaleSkus.includes(item.sku)) &&
        isAfter(
          this.onlineOrder && this.onlineOrder.deadline
            ? new Date(this.onlineOrder.deadline)
            : new Date(),
          new Date('2024-09-20T00:00:00+07:00')
        )
      ) {
        this.items.forEach(item => {
          if (!mooncakeEndSaleSkus.includes(item.sku)) {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = (basePrice - 30000) * item.quantity
          resultMap[item.id].saleProgram = 'Giảm giá bánh trung thu hết mùa còn tồn'
          resultMap[item.id].noLoyalty = true
        })
      }

      if (this.customer && get(this.computedCustomer, 'boughtSavorStoreSalesMap')) {
        const salePriceMap = { V9084: 10000, V2861: 20000 }
        const skus = keys(salePriceMap)
        const boughtMap: { [key: string]: any } = get(
          this.computedCustomer,
          'boughtSavorStoreSalesMap',
          {}
        )
        this.items.forEach(item => {
          if (!includes(skus, item.sku)) {
            return item
          }
          const boughtQty = boughtMap[item.sku] || 0
          if (boughtQty >= 2) {
            return item
          }
          const discountQty = Math.min(boughtQty ? 2 - boughtQty : 2, item.quantity)
          const basePrice = getPlatformPrice(item, this.orderPlatform)

          resultMap[item.id].discount = (basePrice - get(salePriceMap, item.sku)) * discountQty
          resultMap[item.id].saleProgram = 'Giảm giá cho khách hàng thân thiết'
          resultMap[item.id].noLoyalty = true
        })
      }

      let date
      if (this.rootStore.currentShiftStore!.currentShift) {
        date = this.rootStore.currentShiftStore!.currentShift!.date
      }

      // _.forEach(this.items, item => {
      //   const program = this.getBuy1Get1Program(item)

      //   if (!program) {
      //     return
      //   }

      //   _.forEach(item.modifiers, modifier => {
      //     if (modifier.sku !== get(program, 'modifier')) {
      //       return
      //     }
      //     const discountQuantity = item.quantity
      //     const modifierPrice = getModifierPrice(modifier, this.orderPlatform) || 0
      //     resultMap[item.id].discount = modifierPrice * discountQuantity
      //     resultMap[item.id].saleProgram = get(program, 'saleProgram')
      //   })
      // })
      const pannaCottaSkus = ['V8581', 'V8579', 'V8577', 'V8571', 'V8573', 'V8575', 'V8594']

      if (isAfter(deliverDeadline, new Date('2024-09-24T00:00:00+07:00'))) {
        //  sale cho đến khi bếp hết nguyên liệu báo dừng nhận đơn

        _.forEach(this.items, item => {
          if (!_.includes(pannaCottaSkus, item.sku)) {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = ((basePrice * 20) / 100) * item.quantity
          resultMap[item.id].saleProgram = 'Flashsale Panna Cotta'
          resultMap[item.id].noLoyalty = true
        })
      }
    }

    if (this.orderPlatform === 'GRAB_FOOD') {
      const isJuneSale =
        isAfter(new Date(), new Date('2024-06-03T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2024-07-01T00:00:00+07:00'))
      if (isJuneSale && this.couponCode === 'HOT_PREVENTION') {
        let maxDiscountQty = 1
        this.items.forEach(item => {
          // V2329 Trà Ô Long nhài sữa trân châu (M)
          if (!maxDiscountQty || item.sku !== 'V2329') {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = (basePrice * 50) / 100
          resultMap[item.id].saleProgram = 'Phòng Nóng Chống Nóng - Giảm giá 50%'
          resultMap[item.id].noLoyalty = true
          maxDiscountQty -= 1
        })
      }
      if (isJuneSale && this.couponCode === 'CUISINE_GOLDEN_WEEK') {
        let maxDiscountQty = 1
        const totalValue = getSubTOtalWithoutPricedGift(
          this.items,
          this.orderPlatform,
          this.orderType
        )
        if (totalValue >= 150000) {
          this.items.forEach(item => {
            // V0608 Trà sữa TCDD size M
            if (!maxDiscountQty || item.sku !== 'V0608') {
              return
            }
            const basePrice = getPlatformPrice(item, this.orderPlatform)
            resultMap[item.id].discount = basePrice
            resultMap[item.id].saleProgram = 'Cuisine Week - Tuần Lễ Vàng - Tặng món 30k'
            resultMap[item.id].noLoyalty = true
            maxDiscountQty -= 1
          })
        }
      }
      if (isBillTotalDiscountValid(this.items, this.orderPlatform, this.orderType)) {
        let numDiscount = 1
        this.items.forEach(item => {
          if (item.sku !== 'V2340' || !numDiscount) {
            return
          }
          const soldPrice = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType
          })
          resultMap[item.id].discount = soldPrice
          resultMap[item.id].saleProgram = 'Chương trình Grab tặng sữa Dutch Lady'
          numDiscount -= 1
        })
      }
    }

    if (this.orderPlatform === 'NOW') {
      if (this.couponCode === 'FLASH_SALE_25_PERCENT') {
        const frameV9093 =
          isAfter(new Date(), new Date('2024-08-05T00:00:00+07:00')) &&
          isBefore(new Date(), new Date('2024-08-13T00:00:00+07:00'))

        const frameV9094 =
          dayStringBetween('2024-08-05T00:00:00+07:00', '2024-08-08T00:00:00+07:00') ||
          dayStringBetween('2024-08-09T00:00:00+07:00', '2024-08-10T00:00:00+07:00') ||
          dayStringBetween('2024-08-12T00:00:00+07:00', '2024-08-13T00:00:00+07:00')
        this.items.forEach(item => {
          if (!['V9093', 'V9094'].includes(item.sku)) {
            return
          }
          if (!frameV9093 && item.sku === 'V9093') {
            return
          }
          if (!frameV9094 && item.sku === 'V9094') {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = (basePrice * 25) / 100
          resultMap[item.id].saleProgram = 'Flash Sale 25%'
          resultMap[item.id].noLoyalty = true
        })
      }
      if (
        isAfter(new Date(), new Date('2024-01-18T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2024-02-01T00:00:00+07:00')) &&
        this.couponCode === 'SPF_TNMO_50%'
      ) {
        this.items.forEach(item => {
          if (item.sku !== 'V0287') {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = (basePrice - 17500) * item.quantity
          resultMap[item.id].saleProgram = 'SPF Sale 50% Bánh mì TNMO'
          resultMap[item.id].noLoyalty = true
        })
      }

      const saleBannerDay =
        isAfter(new Date(), new Date('2024-03-12T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2024-03-15T00:00:00+07:00'))
      if (saleBannerDay && this.couponCode === 'SAVOR40K') {
        this.items.forEach(item => {
          // V2471 Bánh mì thịt nướng mật ong + Trà Ô long kim quất giá gốc 50k
          // V9916 Bánh mì gà sốt teriyaki + Trà Ô long chanh leo giá gốc giá gốc 45k
          // V8311 Combo bánh mì thịt nướng mật ong (to) + cafe sữa đá (ShopeeFood) giá gốc 50k
          if (!['V2471', 'V9916', 'V8311'].includes(item.sku)) {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = (basePrice - 10000) * item.quantity
          resultMap[item.id].saleProgram = 'Special Banner'
          resultMap[item.id].noLoyalty = true
        })
      }
      const saleHalfDay =
        isAfter(new Date(), new Date('2023-12-12T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2023-12-13T00:00:00+07:00'))
      if (saleHalfDay && this.couponCode === 'SPF_COMBO_50%') {
        this.items.forEach(item => {
          // V2471 Bánh mì thịt nướng mật ong + Trà Ô long kim quất giá gốc 50k
          // V9916 Bánh mì gà sốt teriyaki + Trà Ô long chanh leo giá gốc giá gốc 45k
          if (item.sku !== 'V2471') {
            return
          }
          const basePrice = getPlatformPrice(item, this.orderPlatform)
          resultMap[item.id].discount = (basePrice - 25000) * item.quantity
          resultMap[item.id].saleProgram = 'SPF Sale 50% Combo'
          resultMap[item.id].noLoyalty = true
        })
      }

      if (
        isAfter(new Date(), new Date('2023-08-01T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2023-08-09T00:00:00+07:00'))
      ) {
        this.items.forEach(item => {
          // V0695 hồng trà sữa M
          if (item.sku !== 'V0695') {
            return
          }
          const soldPriceWithoutModifier = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType,
            withoutModifier: true
          })
          resultMap[item.id].discount = (soldPriceWithoutModifier - 8000) * item.quantity
          resultMap[item.id].saleProgram = 'Flat 8k'
          resultMap[item.id].noLoyalty = true
        })
      }

      if (
        isAfter(new Date(), new Date('2023-08-01T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2023-09-01T00:00:00+07:00'))
      ) {
        // V0096 Bánh mì gà sốt teriyaki
        // V0287 Bánh mì thịt nướng mật ong
        // V2331 Trà Ô Long chanh leo (M)
        const weekday = new Date().getDay()
        if ([3, 4].includes(weekday)) {
          this.items.forEach(item => {
            if (!['V0096', 'V2331', 'V0287'].includes(item.sku)) {
              return
            }
            // chỉ sale thứ 4
            if (['V0096'].includes(item.sku) && weekday !== 3) {
              return
            }
            // chỉ sale thứ 5
            if (['V0287'].includes(item.sku) && weekday !== 4) {
              return
            }
            const basePrice = getPlatformPrice(item, this.orderPlatform)
            resultMap[item.id].discount = basePrice * 0.5 * item.quantity
            resultMap[item.id].saleProgram = 'Flash sale 50%'
            resultMap[item.id].noLoyalty = true
          })
        }
      }

      // if (
      //   this.couponCode === 'DAILY_FLASH_SALE' &&
      //   isBefore(new Date(), new Date('2023-01-01T00:00:00+07:00'))
      // ) {
      //   const weekday = new Date().getDay()
      //   const outlet = this.rootStore.authenticationStore.canUseCurrentOutlet
      //     ? getObjectId(
      //         this.rootStore.currentShiftStore.currentOutlet ||
      //           this.rootStore.currentShiftStore.currentShift!.outlet
      //       )
      //     : getObjectId(this.rootStore.currentShiftStore.currentShift!.outlet)
      //   if ([0, 6].includes(weekday) && !['S6', 'S2'].includes(get(outlet, 'code'))) {
      //     this.items.forEach(item => {
      //       if (['V0710', 'V1805'].includes(item.sku)) {
      //         const basePrice = getPlatformPrice(item, this.orderPlatform)
      //         resultMap[item.id].discount = basePrice * 0.5 * item.quantity
      //       }
      //     })
      //   }
      // }

      // end shopee food - now
    }

    if (this.couponCode === 'LOSHIP_DEAL' && this.orderPlatform === 'LOSHIP') {
      this.items.forEach(item => {
        if (item.category === 'bread') {
          const soldPrice = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType
          })
          const newPrice = 15000
          const discount = (soldPrice - newPrice) * item.quantity
          resultMap[item.id].discount = discount
          resultMap[item.id].saleProgram = 'Chiết khấu bánh mì cho Loship'
          resultMap[item.id].noLoyalty = true
        }
      })
    } else if (
      this.orderPlatform === 'LALAMOVE' &&
      isAfter(new Date(), new Date('2020-11-27T23:59:59+07:00')) &&
      isBefore(new Date(), new Date('2020-11-30T00:00:00+07:00'))
    ) {
      this.items.forEach(item => {
        if (item.sku === 'V1031') {
          const soldPrice = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType
          })

          const discount = (soldPrice - 5000) * item.quantity
          resultMap[item.id].discount = discount
          resultMap[item.id].saleProgram = 'Giảm giá bánh mì xúc xích cho tài xế Lalamove'
          resultMap[item.id].noLoyalty = true
        }
      })
    }

    if (this.orderPlatform === 'BAEMIN') {
      if (
        isBefore(new Date(), new Date('2023-04-19T00:00:00+07:00')) &&
        isAfter(new Date(), new Date('2023-04-15T00:00:00+07:00')) &&
        isComboBaeminT4(this.items)
      ) {
        let comboQuantity = isComboBaeminT4(this.items)
        this.items.forEach(item => {
          if (item.sku !== 'V2333') {
            return
          }
          const discountQuantity = Math.min(comboQuantity, item.quantity)
          if (discountQuantity) {
            const soldPrice = getItemSoldPrice({
              item,
              orderPlatform: this.orderPlatform,
              orderType: this.orderType
            })
            resultMap[item.id].discount = soldPrice * discountQuantity
            comboQuantity -= item.quantity
          }
        })
      }
    }

    if (this.orderPlatform === 'GO_FOOD') {
      if (
        isAfter(new Date(), new Date('2023-08-01T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2023-08-07T00:00:00+07:00'))
      ) {
        const comboMap: { [key: string]: number } = {
          V2471: 45000,
          V2472: 45000,
          V2473: 40500,
          V9916: 40500,
          V2652: 54000
        }
        this.items.forEach(item => {
          if (!['V2471', 'V2472', 'V2473', 'V9916', 'V2652'].includes(item.sku)) {
            return
          }
          const soldPriceWithoutModifier = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType,
            withoutModifier: true
          })
          resultMap[item.id].discount =
            (soldPriceWithoutModifier - comboMap[item.sku]) * item.quantity
          resultMap[item.id].saleProgram = 'Mừng lương về giảm combo 10%'
        })
      }

      if (
        isAfter(new Date(), new Date('2023-08-14T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2023-08-21T00:00:00+07:00'))
      ) {
        this.items.forEach(item => {
          if (item.sku !== 'V2652') {
            return
          }
          const soldPriceWithoutModifier = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType,
            withoutModifier: true
          })
          resultMap[item.id].discount = (soldPriceWithoutModifier - 49000) * item.quantity
          resultMap[item.id].saleProgram = 'Giựt cô hồn'
        })
      }

      if (
        isAfter(new Date(), new Date('2023-08-21T00:00:00+07:00')) &&
        isBefore(new Date(), new Date('2023-08-28T00:00:00+07:00'))
      ) {
        this.items.forEach(item => {
          if (item.sku !== 'V2473') {
            return
          }
          const soldPriceWithoutModifier = getItemSoldPrice({
            item,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType,
            withoutModifier: true
          })
          resultMap[item.id].discount = (soldPriceWithoutModifier - 38000) * item.quantity
          resultMap[item.id].saleProgram = 'Cuối tháng ăn sang'
        })
      }
    }

    return resultMap
  }

  get computedItems(): IComputedItem[] {
    let computedItems = this.items.map(item => {
      const selectedVariant = getSelectedVariant(item)
      const soldPrice = getItemSoldPrice({
        item,
        orderPlatform: this.orderPlatform,
        orderType: this.orderType
      })
      const originalPrice = getOriginalPrice(item, this.orderPlatform, this.orderType)
      const sku = selectedVariant ? selectedVariant.sku : item.sku
      const lineTotal = soldPrice * item.quantity
      const description = selectedVariant
        ? item.description + ' - ' + selectedVariant.description
        : item.description

      let programReminder = ''

      // if (this.orderPlatform === 'SAVOR_SHOP') {
      //   const program = this.getBuy1Get1Program(item)
      //   programReminder = get(program, 'reminder', '')
      // }

      const computedItem: IComputedItem = {
        ...item,
        soldPrice,
        originalPrice,
        lineTotal,
        sku,
        selectedVariant,
        description,
        programReminder
      }

      if (this.voucher && this.voucher.voucherType === 'STAFF_DISCOUNT') {
        computedItem.noLoyalty = true
      }

      const matchDiscountItem = this.discountItemMap[computedItem.id]
      if (this.discountItemMap[computedItem.id]) {
        computedItem.discount = matchDiscountItem.discount
        computedItem.saleProgram = matchDiscountItem.saleProgram
        if (matchDiscountItem.noLoyalty === true) {
          computedItem.noLoyalty = true
        }
      }
      if (item.onlineOrderDiscount && item.onlineOrderDiscount.discount > 0) {
        // ưu tiên discount từ erp trừ staff discount do có code xử lý riêng từ trước ở bên này rồi
        computedItem.discount = item.onlineOrderDiscount.discount
        computedItem.saleProgram =
          item.onlineOrderDiscount.saleProgram || 'Giảm giá theo online order'
      }
      computedItem.lineTotal -= computedItem.discount || 0
      return computedItem
    })

    return computedItems
  }

  getPlatformDiscounts(orderPlatform: TOrderPlatform) {
    return partnerDiscounts.filter(discount => {
      return discount.orderPlatform === orderPlatform
    })
  }

  getPartnerDiscountAmount(matchDiscount: IPartnerDiscount) {
    let amount = matchDiscount.amount || 0
    if (matchDiscount.percentValue && matchDiscount.rate && matchDiscount.maxValue) {
      amount = Math.min(
        matchDiscount.maxValue,
        matchDiscount.percentValue * this.saleTotalForBillDiscount * matchDiscount.rate
      )
    }
    return amount
  }

  getVoucherDiscount(voucher: IVoucher, items: IComputedItem[]) {
    if (!voucher) {
      return 0
    }
    const saleTotal = _.sumBy(items, 'lineTotal')
    if (_.get(voucher, 'voucherType') === 'DISCOUNT_CAKE_ORDER_10_PERCENT' && voucher.quantity) {
      return (saleTotal * voucher.quantity) / 100
    }
    return voucher.quantity || voucher.amount || 0
  }

  get discounts(): IDiscount[] {
    const discounts: IDiscount[] = []
    const platformDiscounts = this.getPlatformDiscounts(this.orderPlatform)
    if (this.voucher && this.voucher.voucherType !== 'NONE') {
      switch (this.voucher.voucherType) {
        case 'OPENING_20_PERCENT':
          discounts.push({
            description: 'Giảm giá khai trương 20%',
            amount: Math.floor((this.withoutDiscountsSubTotal * 20) / 100)
          })
          break
        case 'STAFF_DISCOUNT':
          const percent = this.getPercentDiscount()
          const staffDiscount = getStaffDiscountByBusiness({
            breadDiscount: get(this.computedCustomer, 'discountStaffSavorBread', false),
            cakeDiscount: get(this.computedCustomer, 'discountStaffSavorCake', false),
            percent,
            items: this.items,
            orderPlatform: this.orderPlatform,
            orderType: this.orderType,
            discountItemMap: this.discountItemMap
          })
          discounts.push({
            description: 'Giảm giá nhân viên',
            amount: staffDiscount
          })
          break
        case 'AMOUNT':
          discounts.push({
            description: this.voucher.name,
            amount: this.voucher.amount || this.voucher.quantity || 0
          })
          break
        case 'VOUCHER_50K':
          discounts.push({
            description: this.voucher.name,
            amount: 50000
          })
          break
        case 'DISCOUNT_CAKE_ORDER':
        case 'DISCOUNT_CAKE_ORDER_BIG':
        case 'DISCOUNT_CAKE_ORDER_NORMAL':
        case 'DISCOUNT_CAKE_ORDER_10_PERCENT':
          discounts.push({
            description: this.voucher.name,
            amount: this.getVoucherDiscount(this.voucher, this.computedItems)
          })
          break
      }
      const matchDiscount = platformDiscounts.find(discount => {
        const voucher = this.voucher ? this.voucher.voucherType : ''
        return discount.code === voucher
      })
      if (matchDiscount && this.saleTotalForBillDiscount >= matchDiscount.minValue) {
        const inValidTime =
          matchDiscount.start && matchDiscount.end
            ? isAfter(new Date(), new Date(matchDiscount.start)) &&
              isBefore(new Date(), new Date(matchDiscount.end))
            : false
        const notCheckTime = !matchDiscount.start && !matchDiscount.end
        if (notCheckTime || inValidTime) {
          discounts.push({
            description: `Giảm giá đơn hàng ${this.orderPlatform} áp dụng voucher ${matchDiscount.code}`,
            amount: this.getPartnerDiscountAmount(matchDiscount)
          })
        }
      }
    }
    return discounts
  }

  get withoutDiscountsSubTotal(): number {
    return this.items.reduce((result, item) => {
      if (item.sku !== 'V1408' && getItemDiscount(item, this.discountItemMap) > 0) {
        return result
      }
      const soldPrice = getItemSoldPrice({
        item,
        orderPlatform: this.orderPlatform,
        orderType: this.orderType
      })
      return result + soldPrice * item.quantity
    }, 0)
  }
  get saleTotalForBillDiscount(): number {
    return this.items.reduce((result, item) => {
      const soldPrice = getItemSoldPrice({
        item,
        orderPlatform: this.orderPlatform,
        orderType: this.orderType
      })
      return result + soldPrice * item.quantity - getItemDiscount(item, this.discountItemMap)
    }, 0)
  }

  get subtotal(): number {
    return this.computedItems.reduce((result, item) => {
      return item.lineTotal + result
    }, 0)
  }
  get discountTotal(): number {
    return this.discounts.reduce((result, discount) => {
      return result + discount.amount
    }, 0)
  }
  get saleTotal(): number {
    return Math.max(this.subtotal - this.discountTotal, 0)
  }

  get cashTotal(): number {
    return (
      this.saleTotal -
      this.shippingPay +
      this.shippingCollect -
      this.computedReceivableAmount -
      this.commissionAmount -
      this.deposit
    )
  }

  get toCollectAmount(): number {
    return (
      this.saleTotal -
      this.shippingPay +
      this.shippingCollect -
      this.commissionAmount -
      this.deposit
    )
  }

  get isPendingCustomerCard(): boolean {
    return Boolean(
      this.computedCustomer &&
        this.computedCustomer.registerTime &&
        isBefore(
          startOfDay(new Date()),
          addDays(startOfDay(new Date(this.computedCustomer.registerTime)), 1)
        )
    )
  }

  get customerCardStage(): TCustomerCardStage {
    if (!this.computedCustomer) {
      return 'no customer'
    }
    if (!this.computedCustomer.registered || !this.computedCustomer.registerTime) {
      return 'not registered'
    }
    if (this.isPendingCustomerCard) {
      return 'not activated'
    }
    return 'activated'
  }

  get availableVouchers(): IVoucher[] {
    let vouchers = [...(this.computedCustomer ? this.computedCustomer.vouchers || [] : [])]
    // const showDiscountOpenS17 = checkValidDiscountOpenS17({
    //   outletCode: this.getOutletCode(),
    //   orderPlatform: this.orderPlatform,
    //   typeCheck: 'toShow'
    // })
    if (
      !this.computedCustomer ||
      !this.computedCustomer.registered ||
      !this.computedCustomer.registerTime ||
      this.isPendingCustomerCard
    ) {
      vouchers = vouchers.filter(voucher => voucher.withoutCard)
    }
    const platformDiscounts = this.getPlatformDiscounts(this.orderPlatform)
    const matchDiscount = platformDiscounts.find(discount => discount.code === this.voucherCode)
    if (
      !matchDiscount &&
      this.voucherCode &&
      !['NONE', 'OPENING_20_PERCENT'].includes(this.voucherCode)
    ) {
      toast.error(
        `Mã ${this.voucherCode} không tồn tại. Vui lòng check lại đơn đối tác hoặc báo team TECH nhé`
      )
      return vouchers
    }
    if (matchDiscount) {
      if (this.saleTotalForBillDiscount < matchDiscount.minValue) {
        toast.error('Không đủ điều kiện áp mã giảm giá')
        return vouchers
      }
      vouchers.unshift({
        _id: ObjectId(),
        name: `Giảm giá ${this.orderPlatform}`,
        voucherType: matchDiscount.code,
        amount: this.getPartnerDiscountAmount(matchDiscount)
      })
    }

    // if (vouchers.length || showDiscountOpenS17) {
    //   vouchers.push({
    //     _id: ObjectId(),
    //     name: 'Không sử dụng',
    //     voucherType: 'NONE',
    //     amount: 0
    //   })
    // }
    if (
      this.computedCustomer &&
      this.computedCustomer.isStaff &&
      !some(get(this.onlineOrder, 'items', []), item => {
        return ['V2204', 'V2205'].includes(item.sku)
      }) &&
      (this.computedCustomer.discountStaffSavorBread ||
        this.computedCustomer.discountStaffSavorCake)
    ) {
      const percent = this.getPercentDiscount()

      const staffDiscount = getStaffDiscountByBusiness({
        breadDiscount: get(this.computedCustomer, 'discountStaffSavorBread', false),
        cakeDiscount: get(this.computedCustomer, 'discountStaffSavorCake', false),
        percent,
        items: this.items,
        orderPlatform: this.orderPlatform,
        orderType: this.orderType,
        discountItemMap: this.discountItemMap
      })
      const discountObj: IVoucher = {
        _id: ObjectId(),
        name: 'Giảm giá nhân viên',
        voucherType: 'STAFF_DISCOUNT',
        amount: staffDiscount
      }
      vouchers.unshift(discountObj)
      // ! đoạn code bị cmt dưới gây lỗi giảm giá nv: iterations quá nhiều lần
      // if (!this.voucher) {
      //   vouchers.unshift(discountObj)
      // } else if (['NONE', 'STAFF_DISCOUNT'].includes(this.voucher.voucherType)) {
      //   vouchers.push(discountObj)
      // } else {
      //   vouchers.unshift(discountObj)
      // }
    }
    // if (showDiscountOpenS17) {
    //   const discountOpening: IVoucher = {
    //     _id: ObjectId(),
    //     name: 'Giảm giá khai trương 20%',
    //     voucherType: 'OPENING_20_PERCENT',
    //     amount: Math.floor((this.withoutDiscountsSubTotal * 20) / 100)
    //   }
    //   if (!this.voucher) {
    //     vouchers.unshift(discountOpening)
    //   } else if (['NONE', 'STAFF_DISCOUNT'].includes(this.voucher.voucherType)) {
    //     vouchers.push(discountOpening)
    //   } else {
    //     vouchers.unshift(discountOpening)
    //   }
    // }

    // if (!vouchers.length && this.voucher) {
    //   vouchers = [this.voucher]
    // }
    if (vouchers.length) {
      vouchers.push({
        _id: ObjectId(),
        name: 'Không sử dụng',
        voucherType: 'NONE',
        amount: 0
      })
    }

    return vouchers
  }

  async updateOrder() {
    if (!this.rootStore.currentShiftStore.loggedInShift) {
      throw new Error('Không thể cập nhật đơn khi chưa đăng nhập vào ca trực')
    }

    const updatedOrder: any = {
      orderId: this.orderId,
      items: this.items,
      customer: this.customer,
      computedCustomer: this.computedCustomer,
      voucher: this.voucher,
      orderPlatform: this.orderPlatform,
      payments: this.payments,
      shippingProvider: this.shippingProvider,
      shippingPay: this.shippingPay,
      couponCode: this.couponCode,
      shippingBillNumber: this.shippingBillNumber,
      shippingPaymentMethod: this.shippingPaymentMethod,
      shippingCustomerName: this.shippingCustomerName,
      shippingCustomerPhone: this.shippingCustomerPhone,
      shippingCustomerAddress: this.shippingCustomerAddress,
      onlineOrderId: this.onlineOrderId,
      status: this.status,
      ordered: this.ordered,
      updated: new Date(),
      discounts: this.discounts,
      shippingCollect: this.shippingCollect,
      computedItems: this.computedItems,
      discountItemMap: this.discountItemMap,
      subtotal: this.subtotal,
      discountTotal: this.discountTotal,
      saleTotal: this.saleTotal,
      deposit: this.deposit,
      availableVouchers: this.availableVouchers,
      cashTotal: this.cashTotal,
      computedReceivableAmount: this.computedReceivableAmount,
      computedReceiveAmount: this.computedReceiveAmount,
      commissionAmount: this.commissionAmount,
      commissionableAmount: this.commissionableAmount,
      commissionPercentage: this.commissionPercentage,
      isPendingCustomerCard: this.isPendingCustomerCard,
      serviceMode: this.computedServiceMode,
      customerCardStage: this.customerCardStage,
      subTotal: this.subtotal
    }

    try {
      await this.rootStore.agent.put('/orders/' + this._id, updatedOrder)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }

  availableVouchersReaction = reaction(
    () => this.availableVouchers.map(voucher => voucher._id),
    () => {
      if (this.voucher) {
        if (this.onlineOrderId) {
          this.availableVouchers.push(this.voucher)
        }
        if (this.availableVouchers.length) {
          const matchVoucher = this.availableVouchers.find(
            voucher => voucher._id === (this.voucher ? this.voucher._id : '')
          )
          if (!matchVoucher) {
            this.voucher = null
          }
        } else {
          this.voucher = null
        }
      }

      if (!this.voucher && this.availableVouchers.length) {
        this.voucher = this.availableVouchers[0]
      }
    }
  )

  addItem(item: IOrderItem) {
    if (
      item.sku === 'V2857' &&
      !includes(map(this.items, 'sku'), 'V2857') &&
      isChinsuGiftValid(this.items)
    ) {
      item.quantity = 3
    }
    if (item.sku === 'V0677' || item.sku === 'V0680') {
      // let giftSku = 'V0680'
      // let giftName = 'Quai vải Savor'
      // if (getHours(new Date()) >= 16) {
      //   giftSku = 'V0677'
      //   giftName = 'Bộ Savor Green'
      // }
      if (
        this.orderPlatform !== 'SAVOR_SHOP' ||
        !this.rootStore.currentShiftStore.currentShift ||
        !this.rootStore.currentShiftStore.currentShift.shiftRecordId.match(/S1/)
      ) {
        return toast.error(
          'Chỉ đơn hàng tại S1 mới được thêm sản phẩm V0677 Bộ Savor Green hoặc V0680 Quai Vải Savor'
        )
      } else if (
        some(this.items, item => {
          return ['V0677', 'V0680'].includes(item.sku)
        })
      ) {
        return toast.error('Mỗi đơn hàng chỉ được có 1 bộ Savor Green hoặc Quai vải')
      }
    }

    this.items.push(item)

    if (some(this.rootStore!.productStatusStore.products, { sku: item.sku, inStock: false })) {
      if (item.variants && item.variants.length > 0) {
        const otherVariant = item.variants.find(variant => variant.sku !== item.sku)
        otherVariant ? this.selectVariant(item, otherVariant) : undefined
      }
    }

    forEach(item.modifiers, modifier => {
      if (
        some(this.rootStore!.productStatusStore.products, {
          sku: modifier.sku,
          inStock: false
        })
      ) {
        this.removeModifier(item, modifier)
      }
    })
  }

  addProductToItems(product: IProduct) {
    const defaultModifiers = product.defaultModifierSkus
      ? flatten(
          product.defaultModifierSkus.map(sku => {
            return modifierStore.modifiers
              .filter(modifier => {
                return modifier.sku === sku
              })
              .map(modifier => ({ ...modifier, quantity: 1 }))
          })
        )
      : []

    this.addItem({
      ...product,
      id: `${Date.now()}`,
      quantity: product.quantity || 1,
      modifiers: defaultModifiers,
      noLoyalty: product.noLoyalty || false
    })
  }

  selectProduct(product: IProduct) {
    const _product = cloneDeep(product)
    forEach(_product.comboElements, comboElement => {
      comboElement.selectedItem = undefined
    })

    this.product = product
  }
  removeProduct() {
    this.product = undefined
  }
  setNormalPrice(normalPrice: number) {
    if (!this.product) {
      return
    }
    this.product.normalPrice = normalPrice
  }

  selectVariant(item: IOrderItem, variant: IVariant) {
    const matchItem = this.items.find(_item => _item.id === item.id)
    if (!matchItem) return
    matchItem.selectedVariant = variant
  }

  selectItemOptionForCombo(comboElement: TComboElement, itemOption: { sku: string }) {
    if (!this.product) {
      return
    }
    const matchComboElement = this.product!.comboElements!.find(
      _comboElement => _comboElement.name === comboElement.name
    )
    if (!matchComboElement) return
    matchComboElement.selectedItem = itemOption
  }

  removeItem(item: IOrderItem) {
    if (item.isCombo) {
      item.comboElements!.forEach(comboElement => {
        const matchcComboElementIndex = this.items.findIndex(_item => _item.comboId === item.id)
        this.items.splice(matchcComboElementIndex, 1)
      })
    }
    const matchItemIndex = this.items.findIndex(_item => _item.id === item.id)
    this.items.splice(matchItemIndex, 1)
  }

  setCustomer(customer: ICustomer) {
    this.customer = customer
  }

  clearCustomer() {
    this.computedCustomer = null
    this.customer = null
    this.voucher = null
  }

  _setComputedCustomer(customer: ICustomer | null) {
    this.computedCustomer = customer
  }

  setVoucher(voucher: IVoucher) {
    this.voucher = voucher
  }

  customerReaction = reaction(
    () => {
      return this.customer ? this.customer._id : null
    },
    async () => {
      if (!this.customer || !this.customer._id) {
        this._setComputedCustomer(null)
        return
      }
      try {
        await this.checkVouchers(this.customer)
      } catch (e) {
        handleAgentErrorResponse(e)
      }
    }
  )

  autoUpdateReaction = reaction(
    () => {
      return {
        _id: this._id,
        items: toJS(this.items),
        computedCustomer: this.computedCustomer,
        voucher: this.voucher,
        orderPlatform: this.orderPlatform,
        payments: toJS(this.payments),
        shippingProvider: this.shippingProvider,
        shippingPay: this.shippingPay,
        shippingCollect: this.shippingCollect,
        couponCode: this.couponCode,
        shippingBillNumber: this.shippingBillNumber,
        shippingPaymentMethod: this.shippingPaymentMethod,
        shippingCustomerName: this.shippingCustomerName,
        shippingCustomerPhone: this.shippingCustomerPhone,
        shippingCustomerAddress: this.shippingCustomerAddress,
        onlineOrderId: this.onlineOrderId,
        status: this.status,
        selectedServiceMode: this.selectedServiceMode
      }
    },
    data => {
      if (!this.autosave) return
      this.updateOrder().catch(e => {
        handleAgentErrorResponse(e)
        this.rootStore.agent.get<IOrder>(`/orders/${data._id}`).then(order => {
          this.rootStore.listOrderStore.findAndUpdateOrder(order)
        })
      })
    }
  )

  async checkVouchers(customer: ICustomer) {
    const responseCustomer = await this.rootStore.agent.get<ICustomer>(
      `/customers/${customer.customerId}/value-vouchers/?business=SAVOR`
    )
    const vouchers =
      this.onlineOrderId && this.voucher
        ? [this.voucher]
        : (responseCustomer.vouchers || []).map(voucher => {
            return {
              ...voucher,
              amount: this.getVoucherDiscount(voucher, this.computedItems)
            }
          })
    this._setComputedCustomer({
      ...customer,
      customerPoints: responseCustomer.customerPoints,
      isStaff: responseCustomer.isStaff,
      numRemainDiscount: responseCustomer.numRemainDiscount,
      remainDiscountValue: responseCustomer.remainDiscountValue,
      drinkSkus: responseCustomer.drinkSkus,
      mooncakeSkus: responseCustomer.mooncakeSkus,
      mooncakePercent: responseCustomer.mooncakePercent,
      numRemainMooncakeDiscount: responseCustomer.numRemainMooncakeDiscount,
      tetCakeCandySkus: responseCustomer.tetCakeCandySkus,
      tetCakeCandyProducts: responseCustomer.tetCakeCandyProducts,
      tetCakeCandyPercent: responseCustomer.tetCakeCandyPercent,
      boughtSavorStoreSalesMap: responseCustomer.boughtSavorStoreSalesMap,
      discountStaffSavorBread: responseCustomer.discountStaffSavorBread,
      discountStaffSavorCake: responseCustomer.discountStaffSavorCake,
      numSaleBread: responseCustomer.numSaleBread,
      vouchers
    })
  }

  changeItemQuantity(item: IOrderItem, quantity: number) {
    const matchItem = this.items.find(_item => _item.id === item.id)
    if (matchItem) {
      if (quantity === 0) {
        remove(this.items, matchItem)
      } else {
        matchItem.quantity = quantity
      }
    }
  }

  changeItemManufacturingDate(item: IOrderItem, date: Date) {
    const matchItem = this.items.find(_item => _item.id === item.id)
    if (matchItem) {
      matchItem.manufacturingDate = date
    }
  }
  changeItemNote(item: IOrderItem, note: string) {
    const matchItem = this.items.find(_item => _item.id === item.id)
    if (matchItem) {
      matchItem.note = note
    }
  }
  @action
  changeItemCanDiscount(item: IOrderItem, canDiscount: boolean) {
    const matchItem = this.items.find(_item => _item.id === item.id)
    if (matchItem) {
      matchItem.canDiscount = canDiscount
    }
  }

  addModifier(item: IOrderItem, modifier: IModifier) {
    const matchItem = this.items.find(_item => _item.id === item.id)

    if (!matchItem) {
      return
    }

    const modifierSet = modifierStore.modifierSets.find(
      modifierSet => modifierSet.code === modifier.modifierSetCode
    )

    if (modifierSet && modifierSet.maxTotalQuantity) {
      const totalModifierQuantity = item.modifiers
        .filter(modifier => {
          return modifier.modifierSetCode === modifierSet.code
        })
        .reduce((result, modifier) => {
          return result + modifier.quantity
        }, 0)

      if (totalModifierQuantity >= modifierSet.maxTotalQuantity) {
        if (modifierSet.maxTotalQuantity === 1) {
          matchItem.modifiers = matchItem.modifiers.filter(modifier => {
            return modifier.modifierSetCode !== modifierSet.code
          })
        } else {
          return `Lựa chọn ${modifierSet.name} vượt quá số lượng tối đa là ${modifierSet.maxTotalQuantity}`
        }
      }
    }

    const matchModifier = matchItem.modifiers.find(
      itemModifier => itemModifier.sku === modifier.sku
    )

    if (matchModifier) {
      if (modifier.maxQuantity) {
        if (matchModifier.quantity >= modifier.maxQuantity) {
          return `Lựa chọn ${modifier.description} vượt quá số lượng tối đa là ${modifier.maxQuantity}`
        }
      }
      matchModifier.quantity = matchModifier.quantity || 0
      matchModifier.quantity += 1
    } else {
      matchItem.modifiers.push({
        ...modifier,
        quantity: 1
      })
    }
  }

  subtractModifier(item: IOrderItem, modifier: IModifier) {
    const matchItem = this.items.find(_item => _item.id === item.id)

    if (!matchItem) {
      return
    }

    const matchModifier = matchItem.modifiers.find(
      itemModifier => itemModifier.sku === modifier.sku
    )
    if (matchModifier) {
      matchModifier.quantity = matchModifier.quantity || 0
      matchModifier.quantity -= 1
      if (matchModifier.quantity === 0) {
        remove(matchItem.modifiers, matchModifier)
      }
    } else {
      return
    }
  }

  removeModifier(item: IOrderItem, modifier: IModifier) {
    const matchItem = this.items.find(_item => _item.id === item.id)

    if (!matchItem) {
      return
    }

    const modifierSet = modifierStore.modifierSets.find(
      modifierSet => modifierSet.code === modifier.modifierSetCode
    )

    if (modifierSet && modifierSet.minTotalQuantity) {
      const totalModifierQuantity = item.modifiers
        .filter(modifier => {
          return modifier.modifierSetCode === modifierSet.code
        })
        .reduce((result, modifier) => {
          return result + modifier.quantity
        }, 0)
      if (totalModifierQuantity <= modifierSet.minTotalQuantity) {
        return
      }
    }

    const matchModifier = matchItem.modifiers.find(
      itemModifier => itemModifier.sku === modifier.sku
    )
    if (matchModifier) {
      remove(matchItem.modifiers, matchModifier)
    } else {
      return
    }
  }

  clearOrder() {
    this._id = ObjectId()
    this.shippingCustomerName = ''
    this.shippingCustomerPhone = ''
    this.shippingCustomerAddress = ''
    this.onlineOrderId = ''
    this.status = null
    this.ordered = undefined
    this.updated = undefined
    this.onlineOrder = undefined
    this.deposit = 0
    this.orderId = ''
    this.items = []
    this.customer = null
    this.computedCustomer = null
    this.voucher = checkValidDiscountOpenS17({
      outletCode: this.getOutletCode(),
      orderPlatform: 'SAVOR_SHOP',
      typeCheck: 'toShow'
    })
      ? head(this.availableVouchers) || null
      : null
    this.selectedOrderPlatform = 'SAVOR_SHOP'
    this.payments = []
    this.shippingProvider = null
    this.shippingPay = 0
    this.shippingCollect = 0
    this.couponCode = ''
    this.shippingBillNumber = ''
    this.shippingPaymentMethod = ''
    this.selectedServiceMode = ''
    this.openModalPayment = false
    this.saving = false
    this.receivedMoney = null
    this.nowOrderCode = ''
    this.momoTransId = ''
    this.orderCode = null
    this.goFoodPin = null
    this.transferCode = null
    this.groupPaid = false
  }

  setSaving(status: boolean) {
    this.saving = status
  }

  validateOrder() {
    if (some(this.items, { sku: 'V9117' })) {
      throw new Error(
        'Sản phẩm Trà đá V9117 chỉ được bán kèm bánh hoặc xôi. Vui lòng chọn trong phần addon'
      )
    }
    if (
      this.voucher &&
      this.voucher.voucherType === 'OPENING_20_PERCENT' &&
      some(this.items, {
        category: 'birthday-cake'
      })
    ) {
      throw new Error('Sản phẩm BSN không dược áp dụng sale khai trương tại cơ sở của bạn')
    }
    const canUseCurrentOutlet = this.rootStore.authenticationStore.canUseCurrentOutlet
    const currentOutlet = this.rootStore!.currentShiftStore.currentOutlet
    if (!this.rootStore.currentShiftStore.loggedInShift && !canUseCurrentOutlet) {
      throw new Error('Chưa thể tạo đơn khi chưa đăng nhập vào ca trực')
    }

    if (!this.rootStore.currentShiftStore.loggedInShift && canUseCurrentOutlet && !currentOutlet) {
      throw new Error('Bạn chưa đăng nhập cũng như chọn cơ sở cho đơn hàng!')
    }
    if (!this.rootStore.currentShiftStore.loggedInShift && !canUseCurrentOutlet) {
      throw new Error('Chưa thể tạo đơn khi chưa đăng nhập vào ca trực')
    }
    if (
      this.orderPlatform === 'SAVOR_SHOP' &&
      some(this.items, item => {
        return ['gojek-combo', 'befood-combo', 'baemin-combo', 'now-combo'].includes(
          get(item, 'subCat', '')
        )
      })
    ) {
      throw new Error('Không được nhập combo đối tác vào đơn shop')
    }
    if (!this.rootStore.currentShiftStore.loggedInShift && canUseCurrentOutlet && !currentOutlet) {
      throw new Error('Bạn chưa đăng nhập cũng như chọn cơ sở cho đơn hàng!')
    }

    if (!this.computedServiceMode) {
      this.setSelectedServiceMode('delivery')
    }

    if (!this.orderCode && this.orderPlatform !== 'SAVOR_SHOP' && this.orderPlatform !== 'NOW') {
      throw new Error('Bạn chưa nhập Code cho đơn hàng')
    }
    if (this.orderCode && this.orderPlatform !== 'LOSHIP' && this.orderCode.match(/\D+/)) {
      throw new Error(`Mã đơn chỉ chứa số`)
    }
    if (this.orderPlatform === 'GO_FOOD') {
      if (get(this.orderCode, 'length', 0) < 4) {
        throw new Error(`Mã đơn Go Food phải gồm 4 ký tự`)
      }
      if (get(this.goFoodPin, 'length', 0) < 4) {
        throw new Error(`Mã PIN Go Food phải gồm 4 ký tự`)
      }
    }
    if (this.orderPlatform === 'BE_FOOD') {
      if (get(this.orderCode, 'length', 0) < 3) {
        throw new Error(`Mã đơn Go Food phải gồm 3 ký tự`)
      }
    }
    if (this.payments && this.payments.length === 0) {
      throw new Error('Chưa chọn hình thức thanh toán')
    } else {
      if (
        some(this.payments, { paymentType: 'CASH' }) &&
        this.receivedMoney !== 0 &&
        !this.receivedMoney
      ) {
        throw new Error('Chưa nhập số tiền nhận')
      }
    }
    if (this.activePaymentType === 'AIRPAY' && this.orderPlatform !== 'NOW') {
      const values = _.get(_.find(this.payments, { paymentType: 'AIRPAY' }), 'codes')
      if (!_.get(values, 'length')) {
        throw new Error('Chưa nhập mã ShopeePay (AirPay)')
      }
    }

    const { shift, date } = this.rootStore.currentShiftStore.currentShift || {
      shift: '',
      date: ''
    }
    const isAdmin = this.rootStore!.authenticationStore.isAdmin

    if (!isAdmin && !isSameDay(date, new Date())) {
      throw new Error('Ca trực không hợp lệ, Bạn kiểm tra lại nhé !')
    }

    const outlet = canUseCurrentOutlet
      ? getObjectId(
          this.rootStore.currentShiftStore.currentOutlet ||
            this.rootStore.currentShiftStore.currentShift!.outlet
        )
      : getObjectId(this.rootStore.currentShiftStore.currentShift!.outlet)

    if (this.orderPlatform === 'NOW' && !this.nowOrderCode) {
      throw new Error('Chưa chọn mã NOW cho đơn hàng')
    }

    const newOrder: IOrder = {
      _id: this._id,
      orderId: this.orderId,
      status: 'ordered',
      date,
      shift,
      outlet: getObjectId(outlet),
      items: this.items,
      discounts: this.discounts,
      customer: this.computedCustomer,
      voucher: this.voucher,
      orderPlatform: this.orderPlatform,
      selectedOrderPlatform: this.selectedOrderPlatform,
      availableOrderPlatforms: this.availableOrderPlatforms,
      payments: this.payments,
      computedItems: this.computedItems,
      computedCustomer: this.computedCustomer,
      subTotal: this.subtotal,
      discountTotal: this.discountTotal,
      availableVouchers: this.availableVouchers,
      saleTotal: this.saleTotal,
      deposit: this.deposit,
      created: new Date(),
      ordered: new Date(),
      couponCode: this.couponCode,
      shippingBillNumber: this.shippingBillNumber,
      shippingPaymentMethod: this.shippingPaymentMethod,
      shippingProvider: this.shippingProvider,
      shippingPay: this.shippingPay,
      shippingCollect: this.shippingCollect,
      shippingCustomerName: this.shippingCustomerName,
      shippingCustomerPhone: this.shippingCustomerPhone,
      shippingCustomerAddress: this.shippingCustomerAddress,
      onlineOrderId: this.onlineOrderId,
      serviceMode: this.computedServiceMode,
      cashTotal: this.cashTotal,
      computedReceivableAmount: this.computedReceivableAmount,
      computedReceiveAmount: this.computedReceiveAmount,
      commissionAmount: this.commissionAmount,
      commissionableAmount: this.commissionableAmount,
      commissionPercentage: this.commissionPercentage,
      nowOrderCode: this.nowOrderCode,
      momoTransId: this.momoTransId,
      orderCode: this.orderCode,
      goFoodPin: this.goFoodPin,
      transferCode: this.transferCode,
      groupPaid: this.groupPaid
    }

    if (this.receivedMoney) {
      newOrder.receivedMoney = this.receivedMoney
    }

    // MONKEY PATCHING THE PROBLEM
    const errorItems = newOrder.computedItems.filter(item => {
      if (includes(item.defaultModifierSkus, 'V0353') && !some(item.modifiers, { sku: 'V0353' })) {
        return true
      }
      if (item.sku === 'V0377') {
        return !some(item.modifiers, { sku: 'V0361' })
      }
      if (item.sku === 'V0378') {
        return !some(item.modifiers, { sku: 'V0362' })
      }
      if (item.sku === 'V0379') {
        return !some(item.modifiers, { sku: 'V0350' }) || !some(item.modifiers, { sku: 'V0359' })
      }
      if (item.sku === 'V0380') {
        return (
          !some(item.modifiers, { sku: 'V0356' }) ||
          !some(item.modifiers, { sku: 'V0358' }) ||
          !some(item.modifiers, { sku: 'V0383' })
        )
      }
      if (item.sku === 'V0381') {
        return (
          !some(item.modifiers, { sku: 'V0355' }) ||
          !some(item.modifiers, { sku: 'V0356' }) ||
          !some(item.modifiers, { sku: 'V0358' })
        )
      }
      if (item.sku === 'V0382') {
        return !some(item.modifiers, { sku: 'V0360' }) || !some(item.modifiers, { sku: 'V0383' })
      }
      if (item.sku === 'V0385') {
        return !some(item.modifiers, { sku: 'V0353' }) && !some(item.modifiers, { sku: 'V0354' })
      }
      return false
    })

    if (errorItems.length) {
      const item = errorItems[0]
      throw new Error(`Sản phẩm ${item.description} chưa có đủ các addon chính trong thành phần`)
    }

    return {
      newOrder,
      outlet,
      shift,
      date
    }
  }

  async sendOrder() {
    try {
      const { newOrder } = this.validateOrder()

      this.setSaving(true)
      await this.rootStore.agent.post<IOrder>('/orders', newOrder)
      this.setOpenModalPayment(false)
    } catch (e) {
      this.setSaving(false)
      this.setOpenModalPayment(true)
      throw e
    } finally {
      this.setSaving(false)
    }

    this.clearOrder()
  }

  async createAndDoneOrder() {
    try {
      const { newOrder, outlet, shift, date } = this.validateOrder()

      this.setSaving(true)

      const response = await this.rootStore.agent.post('/orders/create-and-done', {
        newOrder,
        currentShift: {
          outlet,
          shift,
          date
        }
      })

      const { order, saleEntry } = response

      this.setOrderAndSaleEntryPrinting(order, saleEntry)
    } catch (e) {
      this.setSaving(false)
      throw e
    } finally {
      this.setSaving(false)
    }

    this.clearOrder()
  }

  setOrderAndSaleEntryPrinting(order: any, saleEntry: any) {
    this.orderPrinting = order
    this.saleEntryPrinting = saleEntry
  }

  setShippingData(data: {
    shippingProvider: TShippingProvider | null
    shippingPay: number
    shippingCollect: number
    shippingBillNumber: string
    shippingPaymentMethod: string
    shippingCustomerName: string
    shippingCustomerPhone: string
    shippingCustomerAddress: string
    onlineOrderId: string
  }) {
    this.shippingBillNumber = data.shippingBillNumber
    this.shippingPaymentMethod = data.shippingPaymentMethod
    this.shippingProvider = data.shippingProvider
    this.shippingPay = data.shippingPay
    this.shippingCollect = data.shippingCollect
    this.shippingCustomerName = data.shippingCustomerName
    this.shippingCustomerPhone = data.shippingCustomerPhone
    this.shippingCustomerAddress = data.shippingCustomerAddress
    this.onlineOrderId = data.onlineOrderId
  }

  get orderPlatform(): TOrderPlatform {
    if (this.availableOrderPlatforms.includes(this.selectedOrderPlatform)) {
      return this.selectedOrderPlatform
    }
    return head(this.availableOrderPlatforms) || 'SAVOR_SHOP'
  }

  get availableOrderPlatforms(): TOrderPlatform[] {
    if (this.autosave) {
      return [
        'SAVOR_SHOP',
        'NOW',
        'LOSHIP',
        'GRAB_FOOD',
        'BAEMIN',
        'BE_FOOD',
        'LALAMOVE',
        'GO_FOOD',
        'VINID'
      ]
    }
    if (this.rootStore.currentShiftStore.loggedInShift) {
      return [
        'SAVOR_SHOP',
        'NOW',
        'LOSHIP',
        'GRAB_FOOD',
        'BAEMIN',
        'BE_FOOD',
        'LALAMOVE',
        'GO_FOOD',
        'VINID'
      ]
    }
    return ['SAVOR_SHOP']
  }

  setOrderPlatform(orderPlatform: TOrderPlatform) {
    this.selectedOrderPlatform = orderPlatform
  }

  orderPlatformReaction = reaction(
    () => {
      return {
        loggedInShift: this.rootStore.currentShiftStore.loggedInShift,
        canUseCurrentOutlet: this.rootStore.authenticationStore.canUseCurrentOutlet
      }
    },
    ({ loggedInShift, canUseCurrentOutlet }) => {
      this.setOrderPlatform('SAVOR_SHOP')
    }
  )

  loadOrderStore(order: IOrder) {
    this.orderId = order.orderId
    this.outlet = order.outlet
    this.items = order.items
    this._id = order._id
    this.customer = order.customer
    this.computedCustomer = order.computedCustomer
    this.voucher = order.voucher
    this.selectedOrderPlatform = order.orderPlatform
    this.payments = order.payments
    this.shippingProvider = order.shippingProvider
    this.shippingPay = order.shippingPay
    this.shippingCollect = order.shippingCollect
    this.couponCode = order.couponCode
    this.shippingBillNumber = order.shippingBillNumber
    this.shippingPaymentMethod = order.shippingPaymentMethod
    this.shippingCustomerName = order.shippingCustomerName
    this.shippingCustomerPhone = order.shippingCustomerPhone
    this.shippingCustomerAddress = order.shippingCustomerAddress
    this.onlineOrderId = order.onlineOrderId
    this.status = order.status
    this.ordered = order.ordered
    this.updated = order.updated
    this.selectedServiceMode = order.serviceMode
  }

  setAutoSave(value: boolean) {
    this.autosave = value
  }

  setAutoUpdateProductStatus(value: boolean) {
    this.autoUpdateProductStatus = value
  }

  setSelectedServiceMode(value: string) {
    this.selectedServiceMode = value
  }

  addPayment(payment: IPayment) {
    if (payment.paymentType === 'MOMO') {
      payment.commissionAmount = payment.amount * 0.01
    }
    if (this.payments) {
      this.payments = [payment]
    }
  }

  chooosePaymentType(type: TPaymentType) {
    // info: payments
    let amount = this.saleTotal + this.shippingCollect - this.commissionAmount - this.deposit
    if (this.shippingPaymentMethod === 'customerSubtract') {
      amount -= this.shippingPay
    }
    this.addPayment({
      paymentType: type,
      amount,
      code: '',
      uniqueId: Math.random().toString(36).substring(4),
      commissionAmount: 0,
      codes: []
    })
  }

  get activePaymentType() {
    if (this.payments && this.payments.length) {
      return this.payments[0].paymentType
    }
    return null
  }

  resetPayments() {
    this.payments = []
  }

  removePayment(uniqueId: string) {
    if (this.payments) {
      this.payments = this.payments.filter(payment => payment.uniqueId !== uniqueId)
    }
  }

  setOpenModalPayment(state: boolean) {
    this.openModalPayment = state
  }

  setReceivedMoney(money: number) {
    this.receivedMoney = money
  }
  setOrderCode(code: String) {
    this.orderCode = code
  }
  setGoFoodPin(code: String) {
    this.goFoodPin = code
  }
  setTransferCode(transferCode: String) {
    this.transferCode = transferCode
  }
  toggleGroupPaid() {
    this.groupPaid = !this.groupPaid
  }
  setCodes(codes: string[]) {
    this.payments = map(this.payments, it => {
      it.codes = codes
      return it
    })
  }
  async fetchActiveNowOrders() {
    try {
      const currentShift = this.rootStore!.currentShiftStore.currentShift

      if (!currentShift) return toast.error('Chưa đăng nhập ca trực')

      const nowOrders = await this.rootStore.agent.get('/now-orders', {
        params: {
          currentShiftId: currentShift._id,
          statuses: [1, 4, 2]
        }
      })

      this.setActiveNowOrders(nowOrders)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }
  async fetchOnlineOrder(onlineOrderId: string) {
    try {
      const onlineOrder = await this.rootStore.agent.get('/online-orders/search', {
        params: {
          onlineOrderId
        }
      })
      if (!onlineOrder) return toast.error('Không tìm thấy online order tương ứng')
      this.setOnlineOrder(onlineOrder)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }
  validateSaleCodeForSale({ saleCode }: { saleCode: any }) {
    if (saleCode.typeGroup !== 'bsn') {
      return 'Không phải Sale Code BSN'
    }
    if (saleCode.status === 'disabled') {
      return 'Sale Code đã bị khóa'
    }
    if (saleCode.status === 'pending') {
      return 'Sale Code đang chờ duyệt'
    }
    if (saleCode.expiryDate) {
      const now = new Date()
      if (isAfter(now, new Date(saleCode.expiryDate))) {
        return 'Sale code đã hết hạn'
      }
    }
    const outlet = this.rootStore.authenticationStore.canUseCurrentOutlet
      ? this.rootStore.currentShiftStore.currentOutlet ||
        this.rootStore.currentShiftStore.currentShift!.outlet
      : this.rootStore.currentShiftStore.currentShift!.outlet
    const outletCode = _.get(outlet, 'code')
    if (outletCode && !_.includes(_.map(saleCode.outlets, 'code'), outletCode)) {
      return `Sale code không áp dụng cho cơ sở ${outletCode} hiện tại`
    }
    if (saleCode.quantity <= 0) {
      return 'Sale code đã dùng hết số lượng'
    }
    return ''
  }

  async fetchSaleCode(saleCodeId: string) {
    try {
      const saleCodeData = await this.rootStore.agent.get(`/sale-codes/search`, {
        params: { saleCodeId }
      })
      const saleCode = saleCodeData.saleCode
      if (!saleCode) {
        return toast.error(`Không tìm thấy sale code ${saleCodeId}`)
      }
      this.handleAddSaleCodeToItems(saleCode)
    } catch (e) {
      handleAgentErrorResponse(e)
    }
  }
  handleAddSaleCodeToItems(saleCode: any) {
    // validate sale code
    const error = this.validateSaleCodeForSale({ saleCode })
    if (error) {
      return toast.error(error)
    }
    // validate product
    const saleCodeMatchedProduct = this.findProducts(saleCode.sku)
    if (!saleCodeMatchedProduct) {
      return toast.error(`Không tìm thấy sản phẩm cho sku ${saleCode.sku}`)
    }
    const rootSaleCodeItem: IOrderItem = {
      ...saleCodeMatchedProduct,
      id: `${Date.now()}`,
      modifiers: [],
      noLoyalty: true,
      quantity: saleCode.quantity
    }
    const rootSoldPrice = getItemSoldPrice({
      item: rootSaleCodeItem,
      orderPlatform: this.orderPlatform,
      orderType: this.orderType
    })
    if (saleCode.salePrice > rootSoldPrice) {
      return toast.error(
        `Giá sale code ${saleCode.saleCodeId} (${saleCode.salePrice}) lớn hơn giá bán ${rootSoldPrice}. Vui lòng liên hệ CSKH/MKT để kiểm tra lại`
      )
    }
    const saleCodeItem: IOrderItem = {
      ...rootSaleCodeItem,
      salePrice: saleCode.salePrice,
      saleCode: saleCode._id,
      saleCodeId: saleCode.saleCodeId,
      manufacturingDate: saleCode.manufacturingDate
    }
    this.addItem(saleCodeItem)
    toast.success('Đã thêm sản phẩm từ Sale code')
  }

  async fetchLinkQrTransfer({
    transferContent,
    totalMoney,
    testAutoConfirm
  }: {
    transferContent: string
    totalMoney: number
    testAutoConfirm: boolean
  }) {
    try {
      const dataObj = await this.rootStore.agent.post('/api/payment-transfer/get-payment-link', {
        transferContent,
        totalMoney,
        testAutoConfirm
      })
      if (!get(dataObj, 'link')) {
        this.setLinkQrTransferError(`Có lỗi khi lấy link QR từ server. ${dataObj.message}`)
      } else {
        this.setLinkQrTransfer(dataObj.link)
      }
    } catch (e) {
      if (e instanceof Error) {
        this.setLinkQrTransferError(`Có lỗi khi lấy link QR từ server. ${e.message}`)
        handleAgentErrorResponse(e)
      }
    }
  }

  findProducts(sku: String) {
    return find(this.rootStore!.productStore.products, (product: IProduct) =>
      isEqual(product.sku, sku)
    )
  }

  setOnlineOrder(onlineOrder: any) {
    forEach(onlineOrder.items, item => {
      const matchProduct = this.findProducts(item.sku)
      if (!matchProduct) {
        return
      }
      if (matchProduct.sku === 'V1408') {
        const bundledProducts = reduce(
          item.bundledProducts,
          (result: any, bundled: any) => {
            let array = [1]
            if (bundled.quantity === 2) {
              array = [1, 1]
            }
            forEach(array, quantity => {
              result.push({ sku: bundled.sku, description: bundled.description, quantity })
            })
            return result
          },
          []
        )

        const comboElements = map(matchProduct.comboElements, (combo, index) => {
          let sku = bundledProducts[index].sku
          return {
            name: combo.name,
            selectedItem: {
              sku: bundledProducts[index].sku,
              price: get(find(combo.itemOptions, { sku }), 'price', 0)
            },
            itemOptions: combo.itemOptions,
            maxQuantity: combo.maxQuantity
          }
        })

        const normalPrice = reduce(
          comboElements,
          (result, comboElement) => {
            result +=
              get(comboElement, 'selectedItem.price', 0) * get(comboElement, 'maxQuantity', 1)
            return result
          },
          0
        )
        this.addProductToItems({
          _id: getObjectId('62579fa53fc8f53105a806f8'),
          sku: matchProduct.sku,
          comboElements,
          description: 'Combo 2 bánh sinh nhật',
          normalPrice,
          category: 'combo',
          isCombo: true
        })

        const comboProduct = findLast(this.items, (item: IOrderItem) =>
          isEqual(item.sku, matchProduct.sku)
        )
        comboElements.forEach(comboElement => {
          const matchProduct = cloneDeep(this.findProducts(comboElement.selectedItem!.sku))

          if (matchProduct) {
            matchProduct.comboId = comboProduct!.id
            matchProduct.comboName = comboProduct!.description
            matchProduct.quantity = get(comboElement, 'maxQuantity', 1)
            this.addProductToItems(matchProduct)
          }
        })
        return
      }
      const productItem: IOrderItem = {
        ...matchProduct,
        id: `${Date.now()}`,
        quantity: item.quantity,
        hasSaltedEgg: item.hasSaltedEgg,
        modifiers: [],
        noLoyalty: false,
        normalPrice: item.originalPrice,
        salePrice: item.soldPrice,
        bundledProducts: item.bundledProducts,
        saleCode: _.get(item, 'saleCode._id', null),
        saleCodeId: _.get(item, 'saleCode.saleCodeId', null)
      }
      if (item.discount > 0 && item.saleProgram !== 'Giảm giá nhân viên') {
        productItem.onlineOrderDiscount = {
          discount: item.discount,
          saleProgram: item.saleProgram
        }
      }
      this.addItem(productItem)
    })
    this.onlineOrder = onlineOrder
    if (onlineOrder.voucher) {
      this.voucher = onlineOrder.voucher
    }

    this.deposit = sumBy(onlineOrder.deposits, 'amount')
  }
  setActiveNowOrders(orders: any) {
    this.activeNowOrders = orders
  }

  setNowOrderCode(code: string) {
    this.nowOrderCode = code
  }

  setLinkQrTransferError(error: string) {
    this.linkQrTransferError = error
  }
  setLinkQrTransfer(link: string) {
    this.linkQrTransfer = link
  }
  setLinkQrCode(link: string) {
    this.linkQrcode = link
  }

  setMomoTransId(code: string) {
    this.momoTransId = code
  }
}

decorate(OrderStore, {
  _id: observable,
  nowOrderCode: observable,
  activeNowOrders: observable,
  onlineOrder: observable,
  orderId: observable,
  momoTransId: observable,
  orderPrinting: observable,
  saleEntryPrinting: observable,
  outlet: observable,
  items: observable,
  autosave: observable,
  autoUpdateProductStatus: observable,
  customer: observable,
  computedCustomer: observable,
  voucher: observable,
  orderPlatform: computed,
  availableOrderPlatforms: computed,
  selectedOrderPlatform: observable,
  payments: observable,
  activePaymentType: computed,
  couponCode: observable,
  covidCoupon: observable,
  voucherCode: observable,
  setCouponCode: action,
  setCovidCoupon: action,
  setVoucherCode: action,
  shippingProvider: observable,
  shippingPay: observable,
  shippingCollect: observable,
  shippingBillNumber: observable,
  shippingPaymentMethod: observable,
  shippingCustomerName: observable,
  shippingCustomerPhone: observable,
  shippingCustomerAddress: observable,
  onlineOrderId: observable,
  selectedServiceMode: observable,
  note: observable,
  status: observable,
  ordered: observable,
  updated: observable,
  openModalPayment: observable,
  saving: observable,
  receivedMoney: observable,
  orderCode: observable,
  linkQrTransferError: observable,
  linkQrTransfer: observable,
  linkQrcode: observable,
  discounts: computed,
  computedItems: computed,
  discountItemMap: computed,
  subtotal: computed,
  discountTotal: computed,
  saleTotal: computed,
  availableVouchers: computed,
  cashTotal: computed,
  toCollectAmount: computed,
  computedServiceMode: computed,
  computedReceivableAmount: computed,
  computedReceiveAmount: computed,
  commissionAmount: computed,
  commissionableAmount: computed,
  commissionPercentage: computed,
  isPendingCustomerCard: computed,
  customerCardStage: computed,
  setActiveNowOrders: action,
  setOnlineOrder: action,
  setNowOrderCode: action,
  addItem: action,
  setReceivedMoney: action,
  selectVariant: action,
  setNormalPrice: action,
  selectProduct: action,
  selectItemOptionForCombo: action,
  removeItem: action,
  changeItemQuantity: action,
  changeItemManufacturingDate: action,
  changeItemNote: action,
  addModifier: action,
  subtractModifier: action,
  removeModifier: action,
  clearOrder: action,
  setCustomer: action,
  clearCustomer: action,
  setVoucher: action,
  _setComputedCustomer: action,
  setShippingData: action,
  setOrderPlatform: action,
  loadOrderStore: action,
  setAutoSave: action,
  setAutoUpdateProductStatus: action,
  setSelectedServiceMode: action,
  addPayment: action,
  setCodes: action,
  chooosePaymentType: action,
  resetPayments: action,
  sendOrder: action,
  createAndDoneOrder: action,
  setOrderAndSaleEntryPrinting: action,
  setOpenModalPayment: action,
  removePayment: action,
  setSaving: action,
  setLinkQrTransferError: action,
  setLinkQrTransfer: action,
  setLinkQrCode: action,
  setMomoTransId: action,
  setOrderCode: action,
  setGoFoodPin: action
})
